import React, { useContext, useState } from "react";
import ContentMiddle from "./ContentMiddle";
import team1 from "../../../../Images/team-logo-1.png";
import team2 from "../../../../Images/team-logo-2.png";
import { DataContext } from "../../../../Context/DataProvider";

const SingleList = ({ item }) => {
  const { base_url } = useContext(DataContext);
  const [modal, setModal] = useState(false);

  const handleModal = () => {
    setModal((prevModal) => !prevModal);
  };

  return (
    <div>
      <div
        onClick={handleModal}
        className="data-row cursor-pointer hidden  md:flex gap-4 px-7 mt-5"
      >
        <div className="basis-[28.5%] flex gap-2 items-center">
          <img
            className="w-9"
            src={`${base_url}/api/v1/team/image/${item.home_team}`}
            alt=""
          />
          <p className="team-title  ">{item.home_team_short}</p>
        </div>
        <div className="basis-[8%] flex  gap-1.5 items-center">
          <div
          
            className={`score ${
                item?.nffc_status === 'win'
                ? "bg-secondary/10 text-secondary"
                : item?.nffc_status === 'loss'
                ? "bg-red-100 text-red-700"
                : "bg-orange-100 text-orange-700"
            } whitespace-nowrap`}
          >
            {!item.home_goals ? "0" : item?.home_goals}
          </div>
          <div
            className={`score ${
                item?.nffc_status === 'win'
                ? "bg-secondary/10 text-secondary"
                : item?.nffc_status === 'loss'
                ? "bg-red-100 text-red-700"
                : "bg-orange-100 text-orange-700"
            } whitespace-nowrap`}
          >
            {!item.away_goals ? "0" : item?.away_goals}
          </div>
        </div>
        <div className="basis-[28.5%] flex gap-2 justify-end items-center">
          <img
            className="w-9"
            src={`${base_url}/api/v1/team/image/${item.away_team}`}
            alt=""
          />
          <p className="team-title  ">{item.away_team_short}</p>
        </div>
        <div className="basis-[35%] flex gap-2 justify-end items-center">
          <span className="b-rate b-rate-1 flex justify-between items-center">
            {isNaN(Math.round(item?.home_win_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.home_win_prob * 100) + "%"
            )}
          </span>
          <span className="b-rate flex justify-between items-center">
            {isNaN(Math.round(item?.tie_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.tie_prob * 100) + "%"
            )}
          </span>
          <span className="b-rate flex justify-between items-center">
            {isNaN(Math.round(item?.away_win_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.away_win_prob * 100) + "%"
            )}
          </span>
        </div>
      </div>

      {/* for mobile */}
      <div
        onClick={handleModal}
        className="data-row cursor-pointer completed-matches-table-mobile flex md:hidden gap-5 px-4 mt-5"
      >
        <div className="grid grid-cols-3 basis-[65%] ">
          <div className="flex gap-1 items-center">
            <img
              className="w-5"
              src={`${base_url}/api/v1/team/image/${item.home_team}`}
              alt=""
            />
            <p className="team-title  ">{item.home_team_code}</p>
          </div>
          <div className="flex px-2 justify-center gap-2 items-center">
            <div
              className={`score ${
                item?.nffc_status === 'win'
                ? "bg-secondary/10 text-secondary"
                : item?.nffc_status === 'loss'
                ? "bg-red-100 text-red-700"
                : "bg-orange-100 text-orange-700"
              } whitespace-nowrap`}
            >
              {!item.home_goals ? "0" : item?.home_goals}
            </div>
            <div
              className={`score ${
                item?.nffc_status === 'win'
                ? "bg-secondary/10 text-secondary"
                : item?.nffc_status === 'loss'
                ? "bg-red-100 text-red-700"
                : "bg-orange-100 text-orange-700"
              } whitespace-nowrap`}
            >
              {!item.away_goals ? "0" : item?.away_goals}
            </div>
          </div>
          <div className="flex gap-1 justify-end items-center ">
            <img
              className="w-5"
              src={`${base_url}/api/v1/team/image/${item.away_team}`}
              alt=""
            />
            <p className="team-title  ">{item.away_team_code}</p>
          </div>
        </div>
        <div className="flex basis-[35%] gap-1 justify-end items-center">
          <span className="b-rate b-rate-1 flex justify-between items-center">
            {isNaN(Math.round(item?.home_win_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.home_win_prob * 100) + "%"
            )}
          </span>
          <span className="b-rate flex justify-between items-center">
            {isNaN(Math.round(item?.tie_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.tie_prob * 100) + "%"
            )}
          </span>
          <span className="b-rate flex justify-between items-center">
            {isNaN(Math.round(item?.away_win_prob * 100)) ? (
              <div className="ml-2">--</div>
            ) : (
              Math.round(item?.away_win_prob * 100) + "%"
            )}
          </span>
        </div>
      </div>

      {modal && <ContentMiddle item={item} />}
    </div>
  );
};

export default SingleList;
