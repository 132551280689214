import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBar = ({ progress }) => {
  return (
    <CircularProgressbar
  value={progress}
  text={`${progress}%`}
  styles={buildStyles({
    // Rotation of path and trail, in number of turns (0-1)
    rotation: 1,

    // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'.
    strokeLinecap: 'round',

    // Text size
    textSize: '27px',

    // How long animation takes to go from one percentage to another, in seconds
    pathTransitionDuration: 0.5,

    // Can specify path transition in more detail, or remove it entirely
    // pathTransition: 'none',

    // Colors
    pathColor: `#2C56EB`,
    textColor: '#000',
    trailColor: '#F7F7F8',
    // backgroundColor: 'red',
    
  })}
/>
  );
};

export default ProgressBar;





