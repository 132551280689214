export function searchData(data,text) {
  if (text !== "") {

    const searchWords = text?.trim().split(/\s+/); // Split searched text into individual words
    const filteredResults = data?.filter((player) => {
      const { name } = player;
      const lowerCaseName = name?.toLocaleLowerCase();

      return searchWords?.every((word) =>
        lowerCaseName?.includes(word?.toLowerCase())
      );
    });
    console.log(filteredResults);
    return filteredResults;

  } 

}
