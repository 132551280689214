import React, { useEffect, useState } from "react";
import GoalkeeperTable from "./GoalkeeperTable";
import { searchData } from "../../../../customFunctions/searchData";
import { sortBasedOnKey } from "../../../../customFunctions/sortingFunction.js";
import FilterViaPosition from "../../FilterViaPosition/FilterViaPosition";

const Goalkeeper = ({ list, players }) => {
  const [text, setText] = useState("");
  const [data, setData] = useState([...players]);
  const [posData, setPosData] = useState([...players]);
  
  useEffect(() => {
    if (text !== "") {
      const result = searchData(players, text);
      console.log(result);
      setData(result);
    } else {
      setData(players);
    }
  }, [text]);

  let content;
  if (list === true) {
    content = <GoalkeeperTable players={data} setPosData={setPosData} setPlayers={setData} />;
  } else {
    content = (
      <p
        style={{ color: "#9299AA", fontSize: "16px" }}
        className="text-center mt-5"
      >
        You have not added any players yet
      </p>
    );
  }
  return (
    <div className="bg-base-100 rounded-lg ">
      <div className="px-6 py-4 border-b flex justify-between items-center coach-header">
        <h6 className="font-semibold 2xl:text-base">
          Goalkeeper ({data?.length})
        </h6>
        
      </div>
      <div className="coach-table">{content}</div>
      <div className="searchbar mt-1 px-4 mb-5 ">
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          type="text"
          placeholder="Search for players"
          className="input input-bordered text-center w-full "
        />
      </div>
    </div>
  );
};

export default Goalkeeper;
