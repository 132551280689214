import React, { useContext, useEffect, useState } from "react";
import "./Team.css";
import TeamMembers from "./TeamMembers";
import TeamFormation from "./TeamFormation";
import TeamRight from "./TeamRight";
import { DataContext } from "../../Context/DataProvider";
import { useParams } from "react-router-dom";

const Team = () => {
  const { base_url, token, formationData, teams } = useContext(DataContext);
  const { team } = useParams();
  const [selectedTeam, setSelectedTeam] = useState(teams?.find(item=>item?.name===team));

  useEffect(()=>{
    const selTeam = teams?.find(item=>item?.name===team);
    setSelectedTeam(selTeam);
  },[teams])

  const formations = [];
  formationData?.forEach((data) => {
    formations.push(data?.formation);
  });

  const [selected, setSelected] = useState(selectedTeam?.formation);
  const [pitchData, setPitchData] = useState([]);

  useEffect(()=>{
    setSelected(selectedTeam?.formation);
  },[selectedTeam])

  useEffect(() => {
    const result = formationData?.filter(
      (item) => item?.formation === selected
    );
    setPitchData(result);
  }, [selected, formationData]);


  return (
    <div className="fenida-team pt-10 min-h-screen flex justify-between p-10">
      <TeamMembers pitchData={pitchData} />
      <TeamFormation
        pitchData={pitchData}
        setSelected={setSelected}
        team={selectedTeam}
        formations={[...new Set(formations)]}
      />
      <TeamRight team={team} />
    </div>
  );
};

export default Team;
