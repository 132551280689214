import React, { useCallback, useEffect, useState } from "react";
import decreaseIcon from "../../Images/decrease.png";
import increaseIcon from "../../Images/increase.png";
import levelIcon from "../../Images/level.png";
import { BiSearch } from "react-icons/bi";

const TeamsRankings = ({ data }) => {
  const [dataViaPage, setDataViaPage] = useState([]);
  const [newData, setNewData] = useState([]);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [notFound, setNotFound] = useState(false);

  
  const totalPage = Math.ceil(data?.length / 20);

  const getDataForPage = (pageNumber) => {
    const startIndex = (pageNumber - 1) * 20;
    const endIndex = Math.min(startIndex + 20, data.length);
    return data.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    setDataViaPage(getDataForPage(Number(page)));
    setCurrentPage(page);
  };

  // eslint-disable-next-line array-callback-return
  

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".player-list") &&
        !e.target.closest(".search-box")
      ) {
        setSearchDropdown(false);
      }
    });
  }, []);

  const handleSearch = useCallback(() => {
    if (searchText) {
      const filteredData = data?.filter((d) =>
        d?.team?.toLowerCase().includes(searchText.toLocaleLowerCase())
      );
      if(filteredData.length===0 && searchText ){
        setNotFound(true)
      }
      if(filteredData.length>0 && searchText ){
        setNotFound(false)
      }
      
      setNewData(filteredData);
    }
  }, [searchText]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchText);
    }, 400);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchText, handleSearch]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value.trim());
    setSearchDropdown(true);
  };

  useEffect(() => {
    if (searchText === null || searchText === "") {
      setSearchDropdown(false);
      setNotFound(false);
      setNewData([]);
      setSelectedTeam(null);
      setDataViaPage(getDataForPage(Number(1)));
    }
  }, [searchText]);

  const handleHighlightTeam = (team) => {
    setSearchDropdown(false);
    setSearchText(team?.team);
    setSelectedTeam(team?.team);
    let teamIndex;
    data?.forEach((obj, index) => {
      if (obj?.team === team?.team) {
        teamIndex = index+1;
      }
    });
    const page = Math.ceil(Number(teamIndex) / 20);
    setCurrentPage(page);
    setDataViaPage(getDataForPage(Number(page)));
  };

  const handleReset = () =>{
    setSearchText("");
    setCurrentPage("");
    setNotFound(false);
  }

  let newContent;

  if (newData.length > 0 && searchDropdown) {
    newContent = (
      <ul className=" player-list max-h-[120px]  overflow-y-scroll b w-full z-50 shadow-lg bg-base-100 p-2 rounded-box">
        {newData?.map((player, index) => (
          <li key={index} onClick={() => handleHighlightTeam(player)}>
            <div className="p-3 pt-2">
              <div className="text-sm cursor-pointer">
                <h6 className="text-semibold">{player.team}</h6>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="teams-ranking w-1/3">
      <div className="ranking-header p-5 flex justify-between items-center ">
        <h3>World Rankings </h3>
        <button disabled={searchText==="" || searchText===null} onClick={handleReset} className="btn-text">Reset</button>
      </div>

      <div className="ranking-table p-2">
        <div className="flex gap-3">
          <div className="w-4/6  search-box relative text-sm mb-3">
            <input
              type="text"
              name=""
              value={searchText}
              onChange={handleSearchChange}
              className="w-full input-border p-3 pl-10 rounded-lg outline-none"
              placeholder="Search for a team"
            />

            <button
              className="absolute cursor-pointer top-4 left-4 text-lg"
            >
              <BiSearch />
            </button>
          </div>
          <div className="w-3/6 flex gap-1 items-center search-box relative text-sm mb-3">
            <div className="w-2/5 text-xs ml-2 min-[1600px]:ml-12 ">
              Jump To :
            </div>

            <input
              onChange={(e) => handlePageChange(e.target.value)}
              value={currentPage}
              max={totalPage}
              type="text"
              placeholder={`1-${totalPage}`}
              className="input px-1 h-[40px] text-center input-border outline-none w-3/5"
            />
          </div>
        </div>
        {(notFound) && <div className="text-red-600 mb-3 ml-2 font-[600] " > No data found !!! </div> }
        {newContent}
        <div className="">
          <table className="table custom-table w-full">
            <tbody>
              {(dataViaPage?.length > 0
                ? dataViaPage.sort((a, b) => a.rank - b.rank)
                : data?.sort((a, b) => a.rank - b.rank)?.slice(0, 20)
              )?.map((team, index) => (
                <tr
                  className={`px-3 ${
                    team.rank % 2 !== 0 ? "bg-[#f2f3f6]" : ""
                  } ${team?.team === selectedTeam && "highlighted-border"} `}
                  key={index}
                >
                  <td
                    align="center"
                    className="bg-transparent rounded-l-lg team-position"
                  >
                    {team?.rank}.
                  </td>
                  <td className="bg-transparent team-name">
                    <div className="whitespace-normal">{team?.team}</div>
                  </td>
                  <td className="bg-transparent league-name">
                    <div className="whitespace-normal">{team?.league_code}</div>
                  </td>
                  <td className="bg-transparent team-rating rounded-r-lg text-center">
                    {parseFloat(team?.spi).toFixed(1)}
                  </td>
                  {/* <td className="bg-transparent rounded-r-lg team-status">
                    <div className="px-2">
                      <h4>..</h4>
                      <img src={levelIcon} alt="icon" />
                    </div>
                  </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TeamsRankings;
