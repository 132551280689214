import React, { useEffect, useState } from "react";
import arrow from "../../../Images/arrow.png";

const Season = ({
  position,
  seasonSelected,
  setPositionSelected,
  setSeasonSelected,
  setLeagueSelected,
  setTeamSelected,
  finalData,
  setFirstClick,
  setPlayerInfo,
}) => {
  const [seasonToggle, setSeasonToggle] = useState(false);
  const [seasonItems, setSeasonItems] = useState([]);
// console.log(finalData);
  // get season list of player
  useEffect(() => {
    if (finalData.length) {
      const seasons = [];
      finalData.forEach((item) => {
        // if (item.position === position) {
        //   seasons.push(item.season);
        // }
        seasons.push(item.season);
      });
      const arrData = Array.from(new Set(seasons));
      const seasonFinalItems = arrData.filter(
        (data) => data !== seasonSelected
      );
      setSeasonItems(arrData);
    }
  }, [finalData, seasonSelected]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".season-dropdown") &&
        !e.target.closest(".season-btn")
      ) {
        setSeasonToggle(false);
      }
    });
  }, []);

  const setData = (season) => {
    
    let filterNeeded;

    filterNeeded = finalData?.filter(
      (data) =>  data?.season === season
    );
    console.log('season', seasonSelected);
    if (filterNeeded.length) {
    //   const newestSeasonObj = filterNeeded?.find((data) =>
    //     Math.max.apply(data.season)
    //   );
    //   console.log(newestSeasonObj);
    //   const arrByLatestSeason = filterNeeded?.filter(
    //     (data) => data?.season === newestSeasonObj?.season
    //   );
    //   console.table(filterNeeded);

    //   const arrByMaxMins = filterNeeded?.filter((data) =>
    //     Math.max.apply(Number(data?.minutes))
    //   );
    var highestMinutes = -1; // Assuming all minutes are positive
    var objByMaxMins = null;
    for (var i = 0; i < filterNeeded.length; i++) {
        var currentMinutes = parseInt(filterNeeded[i].minutes); // Convert to number
      
        // Check if the current value is higher than the highest recorded value
        if (currentMinutes > highestMinutes) {
          highestMinutes = currentMinutes;
          objByMaxMins = filterNeeded[i];
        }
      }
    
      const player = objByMaxMins;
      setPlayerInfo(player);
      setPositionSelected(player?.position);
      setLeagueSelected(player?.league);
      setTeamSelected(player?.team_short_name);
    }
  };
  return (
    <div className="relative mt-3">
      <button
        onClick={() => setSeasonToggle(!seasonToggle)}
        className="w-full input-border p-3 rounded-lg outline-none text-sm season-btn flex justify-between items-center bg-base-100"
      >
        <p>{seasonSelected === undefined ? "ami nai" : seasonSelected}</p>
        <img src={arrow} alt="" />
      </button>

      {seasonToggle && seasonItems.length > 0 && (
        <div className="absolute max-h-[250px] overflow-y-scroll season-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
          {seasonItems?.map((item, index) => (
            <p
              key={index}
              onClick={() => {
                setSeasonSelected(item);
                setSeasonToggle(false);
                setFirstClick(false);
                setData(item);
              }}
              className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
            >
              {item}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Season;
