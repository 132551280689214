import React from "react";

const RobustModel = ({ robustData }) => {

    const TeamName = ( name ) => {
        const words = name.split(' ');
        const shortenedName = words.length > 1 ? `${words[0]}` : name;
      
        return shortenedName;
      };

    return (
        <div className="modal-box players-modal p-0">
            <div className="players-modal-header flex justify-between items-center border-b p-5 pb-4">
                <h3 className="font-bold text-lg">Robustness</h3>
                <label
                    htmlFor="robustmodal"
                    className="h-[25px] w-[25px] mt-[-4px] rounded-full flex cursor-pointer justify-center items-center bg-white shadow-lg text-sm border font-semibold "
                >
                    <div className=" ml-px ">x</div>
                </label>
            </div>
            <div className="p-5">
                <table className="players-modal-table robust-table table w-full">
                    <tbody>
                        
                        {robustData?.map(({ season, teams }) =>
                            teams?.map(({ team, league_codes }) =>
                                league_codes?.map(
                                    ({league_code, totalMinutes}) => (
                                        <tr
                                            key={`${season}-${team}-${league_code}`}
                                        >
                                            <td className="rounded-l-lg font-medium">
                                                <div className="pl-2 pr-1">
                                                    {season}
                                                </div>
                                            </td>
                                            <td className="hidden md:block font-semibold">{team}</td>
                                            <td className="md:hidden font-semibold">{team ? TeamName(team): '-'}</td>
                                            <td className="text-right font-medium">{league_code}</td>
                                            <td className="rounded-r-lg font-semibold text-center">{totalMinutes} Minutes</td>
                                        </tr>
                                    )
                                )
                            )
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RobustModel;
