import React, { useEffect, useState } from "react";

const FilterViaPosition = ({ selectedPosition, setSelectedPosition, setText, posData, setPosData, data, setData }) => {
  const [toggle, setToggle] = useState(false);
  
  const positions = [];
  data?.forEach((obj) => {
    const pos = obj?.position;
    positions.push(pos);
  });

  const uniquePositions = [...new Set(positions)];

  console.log(positions);
  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (!e.target.closest(".select-position") || e.target.closest(".drop-item") ) {
        setToggle(false);
      }
      
    });
  }, []);


  const handleFilter = (position) => {
    const filteredData = data?.filter((obj) => obj.position === position);
    setPosData(filteredData);
    console.log("pos",position);
    setSelectedPosition(position);
  };

  const handleClick = () => {
    setText("");
    if(selectedPosition==="All"){
      setPosData(data);
    }
    setToggle(!toggle);
  };

  useEffect(()=>{
    if(data?.length===posData?.length){
      setSelectedPosition("All");
    }
  },[data,posData])

  return (
    <div>
      <div className="relative">
        {
          uniquePositions?.length>1 &&
          <button
          onClick={handleClick}
          className={` py-1 flex ${
            selectedPosition==="All"
              ? "bg-transparent text-black border"
              : "bg-primary text-white"
          } justify-center items-center px-6 rounded select-position  text-xs`}
        >
          {selectedPosition}
        </button>
        }
        

        {toggle && (
          <div className="absolute text-center position-filter-scroll max-h-[300px] overflow-y-scroll league-dropdown select-position w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
            {positions.length > 0 &&
              [...new Set(positions)].map((item, index) => (
                <div
                 
                  key={index}
                  onClick={() => handleFilter(item)}
                  className={`cursor-pointer p-1.5 hover:bg-[#F7F7F8] text-xs text-[rgb(59 130 246 / 0.5)] drop-item duration-300`}
                >
                  {item}
                </div>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FilterViaPosition;
