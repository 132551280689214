import React, { useState } from "react";

const positions = [
  "GK",
  "LCB",
  "RCB",
  "CCB",
  "LB",
  "RB",
  "DM",
  "CM",
  "CAM/SS",
  "LM/LWB",
  "RM/RWB",
  "LW",
  "RW",
  "CF/ST",
];

const Positions = ({
  setPositionSelected,
  setPage,
  activePositions,
  setActivePositions,
  playerHistory,
  setPlayerHistory
}) => {
  const handleClick = (item) => {
    setPositionSelected(item);
    if (activePositions.includes(item)) {
      const items = activePositions.filter((i) => i !== item);
      setActivePositions(items);
    }
    if (!activePositions.includes(item)) {
      setActivePositions([...activePositions, item]);
    }
    setPage(1);
    // setPlayerHistory([])
  };
  // console.log(active);
  return (
    <div className="mt-2 grid grid-cols-3 gap-2 text-xs font-medium">
      {positions?.map((item, index) => (
        <div
          key={index}
          className={` ${
            activePositions.includes(item) && "active-position"
          } border cursor-[pointer] text-center rounded px-2.5 min-[1920px]:px-1.5 py-1.5`}
          onClick={() => handleClick(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default Positions;
