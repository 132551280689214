import React, { useEffect, useState } from "react";
import FullbacksTable from "./FullbacksTable";
import { searchData } from "../../../../customFunctions/searchData";
import { sortBasedOnKey } from "../../../../customFunctions/sortingFunction.js";
import FilterViaPosition from "../../FilterViaPosition/FilterViaPosition";

const Fullbacks = ({ list, players }) => {
  const [text, setText] = useState("");
  const [data, setData] = useState([...players]);
  const [posData, setPosData] = useState([...players]);
  const [selectedPosition, setSelectedPosition] = useState("All");

  useEffect(() => {
    if (text !== "") {
      const result = searchData(players, text);
      setPosData(result);
      setSelectedPosition("All");
    } else {
      setPosData(players);
    }
  }, [text]);

  const handleReset = () => {
    setPosData(data);
    setSelectedPosition("All");
    setText("");
  };

  let content;
  if (list === true) {
    content = (
      <FullbacksTable
        posData={posData}
        setPosData={setPosData}
        players={data}
        setPlayers={setData}
      />
    );
  } else {
    content = (
      <p
        style={{ color: "#9299AA", fontSize: "16px" }}
        className="text-center mt-5"
      >
        You have not added any players yet
      </p>
    );
  }
  return (
    <div className="bg-base-100 rounded-lg ">
      <div className="px-6 py-4 border-b flex justify-between items-center coach-header">
        <div className="flex gap-4 items-center">
          <h6 className="font-semibold 2xl:text-base">
            Fullbacks ({posData?.length > 0 ? posData.length : data?.length})
          </h6>
          {data?.length > 0 && (
            <FilterViaPosition
              setText={setText}
              posData={posData}
              setPosData={setPosData}
              selectedPosition={selectedPosition}
              setSelectedPosition={setSelectedPosition}
              data={data}
              setData={setData}
            />
          )}
        </div>
        {data?.length > 0 && (
          <button
            onClick={handleReset}
            className={`px-4 py-1 flex ${
              selectedPosition === "All"
                ? "bg-transparent border"
                : "border font-semibold cursor-pointer"
            } justify-center items-center ${
              text !== "" && "border font-semibold cursor-pointer"
            }  text-black rounded  text-xs`}
          >
            Reset
          </button>
        )}
      </div>
      <div className="coach-table">{content}</div>
      <div className="searchbar mt-1 px-4 mb-5 ">
        <input
          value={text}
          onChange={(e) => setText(e.target.value)}
          type="text"
          placeholder="Search for players"
          className="input input-bordered text-center w-full "
        />
      </div>
    </div>
  );
};

export default Fullbacks;
