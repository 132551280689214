import React, { useContext, useEffect, useState } from "react";
import "./ShortLists.css";
import Players from "./Players";
import Goalkeeper from "./List/Goalkeeper/Goalkeeper";
import Midfielders from "./List/Midfielders/Midfielders";
import CentreBacks from "./List/CentreBacks/CentreBacks";
import CF from "./List/CF/CF";
import Fullbacks from "./List/Fullbacks/Fullbacks";
import OffensiveForwards from "./List/OffensiveForwards/OffensiveForwards";
import { DataContext } from "../../Context/DataProvider";
import contentLoader from "../../Images/loader.gif";

const ShortLists = () => {
  const {
    base_url,
    user,
    token,
    playerShortListData,
    setPlayerShortListData,
    playerSlLoading,
  } = useContext(DataContext);

  if (playerSlLoading) {
    return (
      <div id="preloader" className="flex items-center justify-center">
        <div className="flex loader-box justify-center items-center ">
          <span className="text-center">
            <img
              className="mx-auto d-block"
              alt="loader"
              width="150"
              src={contentLoader}
            />
          </span>
        </div>
      </div>
    );
  }

  //   if(playerShortListData?.length===0){
  //     return <div className="coach-shortlist font-[700] text-red-600 text-base pt-10 min-h-screen px-10 py-5 " >
  //       Sorry!! You have not added any player yet.
  //     </div>
  //   }

  if (playerShortListData?.length === 0) {
    return (
      <div className="coach-shortlist pt-10 min-h-screen px-10 py-5 ">
        <h2>
          Shortlists (
          {playerShortListData?.length > 0 ? playerShortListData.length : 0})
        </h2>
        <div className="grid grid-cols-3 gap-5  mt-5">
          <Goalkeeper players={[]} list={false} />
          <CentreBacks players={[]} list={false} />
          <Fullbacks players={[]} list={false} />
          <Midfielders players={[]} list={false} />
          <OffensiveForwards players={[]} list={false} />
          <CF players={[]} list={false} />
        </div>
      </div>
    );
  } else {
    const centrebacks =
      playerShortListData?.length > 0 &&
      playerShortListData?.filter(
        (player) =>
          player.position === "LCB" ||
          player.position === "RCB" ||
          player.position === "CCB"
      );
    const fullbacks =
      playerShortListData?.length > 0 &&
      playerShortListData?.filter(
        (player) =>
          player.position === "LB" ||
          player.position === "RB" ||
          player.position === "LM/LWB" ||
          player.position === "RM/RWB"
      );
    const midfielders =
      playerShortListData?.length > 0 &&
      playerShortListData?.filter(
        (player) => player.position === "DM" || player.position === "CM"
      );
    const goalkeepers =
      playerShortListData?.length > 0 &&
      playerShortListData?.filter((player) => player.position === "GK");
    const offForwards =
      playerShortListData?.length > 0 &&
      playerShortListData?.filter(
        (player) =>
          player.position === "RW" ||
          player.position === "LW" ||
          player.position === "CAM/SS"
      );
    const cfs =
      playerShortListData?.length > 0 &&
      playerShortListData?.filter((player) => player.position === "CF/ST");

    return (
      <div className="coach-shortlist pt-5 xl:pt-10 min-h-screen px-4 xl:px-10 py-5 ">
        <h2>
          Shortlists (
          {playerShortListData?.length > 0 ? playerShortListData.length : 0})
        </h2>
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-5  mt-5">
          <Goalkeeper
            players={goalkeepers}
            list={goalkeepers?.length > 0 ? true : false}
          />
          <CentreBacks
            players={centrebacks}
            list={centrebacks?.length > 0 ? true : false}
          />
          <Fullbacks
            players={fullbacks}
            list={fullbacks?.length > 0 ? true : false}
          />
          <Midfielders
            players={midfielders}
            list={midfielders?.length > 0 ? true : false}
          />
          <OffensiveForwards
            players={offForwards}
            list={offForwards?.length > 0 ? true : false}
          />
          <CF players={cfs} list={cfs?.length > 0 ? true : false} />
        </div>
      </div>
    );
  }
};

export default ShortLists;
