import React, { useContext, useState } from "react";
import { DataContext } from "../../Context/DataProvider";

const UpdateTeamModal = ({ team, index }) => {
  const {
    teams,
    setTeams,
    base_url,
    user,
    token,
    assignedPlayers,
    setAssignedPlayers,
  } = useContext(DataContext);
  const [name, setName] = useState(team?.name);


  const handleUpdateTeam = (team) => {
    const alreadyAdded = teams?.find((team) => team?.name === name);
    
    if (alreadyAdded) {
      alert("a team with name already created");
    } else {
      fetch(`${base_url}/api/v1/myteam/1`, {
        method: "PUT",
        body: JSON.stringify({
          user_id: user?.id,
          id: team?.id,
          name: name,

        })
      })
        .then((response) => response.json())
        .then((json) => {
          if (json) {
            teams?.map((t) => {
              if (t?.name === team.name) {
                t.name = name;
              }
            });
            setTeams([...teams]);
            
            assignedPlayers?.map((obj) => {
              console.log("obj", obj);
              if (obj?.team === team.name) {
                obj.team = name;
                obj?.players?.length > 0 &&
                  obj.players.map((player) => {
                    player.createdTeam = name;
                  });
                obj?.benches?.length > 0 &&
                  obj.benches.map((player) => {
                    player.createdTeam = name;
                  });
              }
            });

            setAssignedPlayers([...assignedPlayers]);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    }
  };
  return (
    <div>
      <input
        type="checkbox"
        id={`update-team-modal${index}`}
        className="modal-toggle"
      />
      <div className="modal">
        <div className="modal-box create-team-modal p-0">
          <div className="create-team-modal-header p-5">
            <h3 className="font-bold text-lg">Team Details {team?.name} </h3>
          </div>
          <div className="p-5">
            <div>
              <div>
                <label htmlFor="">Team Name</label>
              </div>
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                placeholder="Enter your team name"
                className="update-team-modal-input input input-bordered w-full mt-3"
              />
            </div>
            
          </div>
          <div className="modal-action modal-footer flex justify-between p-5 ">
            <label htmlFor={`update-team-modal${index}`} className="cancel-btn">
              Cancel
            </label>
            <label
              onClick={() => handleUpdateTeam(team)}
              htmlFor={`update-team-modal${index}`}
              className="confirm-btn"
            >
              Confirm
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateTeamModal;
