import { useContext } from "react";
import { Navigate, useLocation } from "react-router";
import { DataContext } from "../Context/DataProvider";
import contentLoader from "../Images/loader.gif";


const PrivateRoute = ({ children }) => {
    const { user, loading} = useContext(DataContext);
    const location = useLocation();
    
    if(loading){
        return (
            <div id="preloader" className="flex items-center justify-center">
              <div className="flex loader-box justify-center items-center ">
                <span className="text-center">
                  <img
                    className="mx-auto d-block"
                    alt="loader"
                    width="150"
                    src={contentLoader}
                  />
                </span>
              </div>
            </div>
          );
    }
    if (user && user?.email) {
        return children;
    }
    return <Navigate to="/login" state={{from: location}} replace></Navigate>
};

export default PrivateRoute;