import React from "react";
import HistoryTable from "./HistoryTable";
import profileImage from "../../Images/coach-profile.png";
import teamImage from "../../Images/player-team.png";
import playerTrophy from "../../Images/player-trophy.png";
import roundCircle from "../../Images/round-circle.png";

const ProfileInfo = ({coachInfo, stats}) => {
  return (
    <>
      <div className="px-3 md:px-6 py-6 bg-white shadow-lg mt-4 md:mt-6 rounded-[8px] ">
        {/* profile name , image */}
        <div className="flex justify-between items-center ">
          <div className="flex gap-4 items-center ">
            <div className="">
              <img src={profileImage} alt="profile" />
            </div>
            <div>
              <h4 className="text-[#22272F] text-base md:text-lg font-semibold ">
                { coachInfo?.coach }
              </h4>
              <p className="mt-1 text-sm md:text-base font-medium text-[#9299AA] ">
                Born 22 Jul 1998 (Aged {coachInfo?.age})
              </p>
            </div>
          </div>
          <div>
            <img src={teamImage} alt="team" />
          </div>
        </div>

        {/* sec-3 ht, min, age */}
        <div className="mt-4 w-[85%] 2xl:w-[75%] grid grid-cols-2 md:grid-cols-3 gap-4 ">
          <div className="flex flex-col ">
            <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">Team</h4>
            <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">
              {coachInfo?.team}
            </h4>
          </div>
          <div className="flex flex-col ">
            <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">League</h4>
            <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">
            {coachInfo?.league}
            </h4>
          </div>
          <div className="flex flex-col ">
            <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">Start</h4>
            <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">
              July 2023
            </h4>
          </div>
          <div className="flex flex-col ">
            <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">End</h4>
            <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">
              November 2026
            </h4>
          </div>
          <div className="flex flex-col ">
            <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">
              Games played
            </h4>
            <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">{coachInfo?.games}</h4>
          </div>
          <div className="flex flex-col ">
            <h4 className="text-[#9299AA] text-sm md:text-base font-medium ">Games won</h4>
            <h4 className="text-[#22272F] font-semibold text-sm md:text-base ">72%</h4>
          </div>
        </div>
        <hr className="block border-[#F2F3F6] md:hidden mt-4 md:mt-6" />
        {/* key points for mobile */}
        <div className="block md:hidden mt-4 ">
          <h4 className="text-[#9299AA] text-sm font-medium ">Key Points</h4>
          <div className="mt-2 flex flex-col gap-2 ">
            <div className="flex gap-2 ">
              <img className="w-[10px] mt-[5px] h-[10px] " src={roundCircle} alt="circle" />
              <span className="text-[#22272f] text-sm font-medium leading-[20px] ">
                Uses his substitutions late in the game
              </span>
            </div>
            <div className="flex gap-2 ">
              <img className="w-[10px] mt-[5px] h-[10px] " src={roundCircle} alt="circle" />
              <span className="text-[#22272f] text-sm font-medium leading-[20px] ">
                Distributes progresion evenly among the players
              </span>
            </div>
            <div className="flex gap-2 ">
              <img className="w-[10px] mt-[5px] h-[10px] " src={roundCircle} alt="circle" />
              <span className="text-[#22272f] text-sm font-medium leading-[20px] ">
                Allows the opposition to dominate once ahead
              </span>
            </div>
          </div>
        </div>
        {/* sec-4 position and rank */}
        <div className="mt-4 md:mt-6 flex flex-col md:flex-row gap-4 md:gap-6 ">
          <div className=" basis-full md:basis-[40%]">
            <div className="basis-full hidden md:flex rounded-[8px] justify-center items-center flex-col gap-1  border-[1.5px] border-[#7C86A149] p-4  bg-white shadow-lg ">
              <div className="shadow-lg mb-2 w-10 h-10 bg-white border-[1.5px] border-[#7C86A149] flex justify-center items-center rounded-[5px] ">
                <img src={playerTrophy} alt="trophy" />
              </div>
              <div className="text-[#22272F] text-[32px] font-semibold ">
                2nd
              </div>
              <div className="text-[#9299AA] text-base font-medium ">
                out of 25 in SPA1
              </div>
            </div>
            <div className="basis-full flex md:hidden rounded-[8px] justify-between items-center gap-1  border-[1.5px] border-[#7C86A149] p-4  bg-white shadow-lg ">
              <div className="flex items-center gap-3 ">
                <div className="shadow-lg mb-2 w-10 h-10 bg-white border-[1.5px] border-[#7C86A149] flex justify-center items-center rounded-[5px] ">
                  <img src={playerTrophy} alt="trophy" />
                </div>
                <div className="text-[#22272F] mt-[-6px]  text-[21px] font-semibold ">
                  2nd
                </div>
              </div>
              <div className="text-[#9299AA] text-sm font-medium ">
                out of 25 in SPA1
              </div>
            </div>
          </div>
          <div className="basis-full md:basis-[60%] bg-white border-[1.5px] border-[#7C86A149] shadow-lg p-4 rounded-[8px]  ">
            <div className="mt-0 md:mt-3 flex justify-between ">
              <div className=" flex gap-2 items-center ">
                <div className="flex items-center gap-[2px] ">
                  <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#9299AA7A] "></div>
                </div>
                <div className="text-[#9299AA] text-sm font-medium ">
                  Team Quality
                </div>
              </div>
              <div className="text-[#22272F] font-semibold text-sm ">
                Excellent
              </div>
            </div>
            <div className="py-3 mt-3 border-y-[1.5px] border-y-[#F2F3F6] flex justify-between ">
              <div className=" flex gap-2 items-center ">
                <div className="flex items-center gap-[2px] ">
                  <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#02B059] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#9299AA7A] "></div>
                </div>
                <div className="text-[#9299AA] text-sm font-medium ">
                  Performance Impact
                </div>
              </div>
              <div className="text-[#22272F] font-semibold text-sm ">Good</div>
            </div>
            <div className="mt-3 flex justify-between ">
              <div className=" flex gap-2 items-center ">
                <div className="flex items-center gap-[2px] ">
                  <div className="rounded-full h-[6px] w-[6px] bg-[#F29445] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#F29445] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#F29445] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#F29445] "></div>
                  <div className="rounded-full h-[6px] w-[6px] bg-[#9299AA7A] "></div>
                </div>
                <div className="text-[#9299AA] text-sm font-medium ">
                  Short-Term Impact
                </div>
              </div>
              <div className="text-[#22272F] font-semibold text-sm ">
                Average
              </div>
            </div>
          </div>
        </div>

        {/* sec-5 history table */}
        <HistoryTable stats={stats} />
      </div>
    </>
  );
};

export default ProfileInfo;
