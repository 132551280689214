import React, { useEffect, useState } from "react";

const contractExpires = [
  "Less than 6 months",
  "6 to 12 months",
  "12 to 24 months",
  "More than 24 months",
];

const ContactExpiring = ({
  contractSelected,
  setContractSelected,
  setSeasonSelected,
  setTeamSelected,
  setPage,
  selectedLeagues,
  setTeams,
  setSelectedTeams,
}) => {
  const [contractToggle, setContractToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(true);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (!e.target.closest(".contract-btn")) {
        setContractToggle(false);
      }
    });
  }, []);

  const handleClick = () => {
    setContractToggle(!contractToggle);
  };

  const handlecontractSelected = (league) => {
    setLoading(false);
    setContractSelected(league);
    setSeasonSelected("select season");
    setTeamSelected("select team");
    setIsLoad(false);
    setPage(1);
  };

  useEffect(()=>{
    if(selectedLeagues.length===0){
      setSelectedTeams([]);
    }
  },[selectedLeagues])



  useEffect(()=>{
    if(selectedLeagues.length===0){
      setTeams([]);
    }
  },[selectedLeagues])

  return (
    <div className="mt-3 ">
      <h6 className="text-sm" >Contact Expiring</h6>
      {/* <div className=" flex flex-wrap gap-1 ">
        {selectedLeagues.length > 0 &&
          selectedLeagues.map((league, index) => (
            <div
              key={index}
              style={{ fontSize: "11px" }}
              className="p-1 flex gap-1 justify-between items-center text-sm border rounded-e"
            >
              <div>{league}</div>
              <div
                onClick={() => handleRemoveLeague(league)}
                className="border cursor-pointer rounded-full px-1 bg-white shadow-sm"
              >
                x
              </div>
            </div>
          ))}
      </div> */}
      <div className="relative mt-2">
        <button
          onClick={handleClick}
          className="w-full xl:w-full text-sm input-border capitalize p-2 rounded-lg outline-none contract-btn flex  bg-base-100"
        >
          {loading && isLoad ? (
            <svg
              className="animate-spin mx-auto h-5 w-5 text-slate-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          ) : (
            contractSelected.length>0 ? contractSelected : 'Select Contract'
          )}
        </button>

        {contractToggle && (
          <div className="absolute max-h-[300px] overflow-y-scroll league-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
            {contractExpires.map((item, index) => (
              <p
                key={index}
                onClick={() => handlecontractSelected(item)}
                className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
              >
                {item}
              </p>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactExpiring;
