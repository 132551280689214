import React, { useContext, useState } from "react";
import { DataContext } from "../../Context/DataProvider";
import { BiSearch } from "react-icons/bi";
import Season from "./Dropdown/Season";
import Position from "./Dropdown/Position";
import Team from "./Dropdown/Team";
import League from "./Dropdown/League";
import searchBlue from "../../Images/search-blue.png";
import searchFilter from "../../Images/search-filter.png";

const FilterArea = ({
    playerInfo,
    content,
    debounceOnChange,
    positionSelected,
    setPositionSelected,
    seasonSelected,
    setSeasonSelected,
    leagueSelected,
    setLeagueSelected,
    teamSelected,
    setTeamSelected,
    finalData,
    setFirstClick,
    setPlayerInfo,
    positionData,
}) => {
    const {
        base_url,
        token,
        user,
        playerShortListData,
        setPlayerShortListData,
    } = useContext(DataContext);
    const [searchbar, setSearchbar] = useState(false);

    const handleAddOrRemove = (player) => {
        if (!checkSavePlayer(playerShortListData, player?.name_dob)) {
            fetch(`${base_url}/api/v1/player/shortlist`, {
                method: "POST",
                body: JSON.stringify({
                    user_id: user?.id,
                    player_id: player?.id,
                    name_dob: player?.name_dob,
                    name: player?.name,
                    team: player?.team,
                    position: player?.position,
                    x_gdar: player?.x_gdar ? player.x_gdar : "0",
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => response.json())
                .then((json) => {
                    if (json) {
                        setPlayerShortListData([...playerShortListData, json]);
                    }
                })
                .catch((error) => {
                    if (error) {
                        console.log(error);
                    }
                });
        } else {
            const targetedPlayer = checkSavePlayer(
                playerShortListData,
                player?.name_dob
            );
            fetch(`${base_url}/api/v1/player/shortlist/${targetedPlayer?.id}`, {
                method: "DELETE",
                body: JSON.stringify({
                    user_id: user?.id,
                }),
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => res.json())
                .then((result) => {
                    if (result) {
                        const remainingPlayers = playerShortListData?.filter(
                            (obj) => obj?.id !== targetedPlayer?.id
                        );
                        // const targetedPlayer = data?.find((obj) => obj?.id === id);
                        // const filteredPlayer = playerShortListData?.filter(obj=>obj?.id!==targetedPlayer?.id);
                        // setData(remainingPlayers);
                        setPlayerShortListData(remainingPlayers);
                    }
                })

                .catch((error) => {
                    if (error) {
                        console.log(error);
                    }
                });
        }
    };

    function checkSavePlayer(arr, searchName) {
        const foundObject = arr.find((item) => item?.name_dob === searchName);
        return foundObject;
    }
    return (
        <div className="pt-5 md:pt-10 pb-5 px-5 md:px-10">
            <div className="flex flex-col md:flex-row justify-between rounded-lg shadow-lg bg-white">
                <div className="p-3 md:p-6 ">
                    <div className="flex items-center justify-between">
                        <h4 class="text-[#22272F] text-base md:text-lg font-semibold flex">
                            {playerInfo?.name}
                        </h4>
                        <div
                            id="shortlist"
                            className="bg-white md:hidden items-center input-border p-2 lg:p-3 min-[1920px]:p-3 rounded-lg outline-none"
                        >
                            {checkSavePlayer(
                                playerShortListData,
                                playerInfo?.name_dob
                            ) ? (
                                <img
                                    className="cursor-pointer"
                                    onClick={() =>
                                        handleAddOrRemove(playerInfo)
                                    }
                                    src={searchBlue}
                                    alt="icon"
                                />
                            ) : (
                                <img
                                    className="cursor-pointer"
                                    onClick={() =>
                                        handleAddOrRemove(playerInfo)
                                    }
                                    src={searchFilter}
                                    alt="icon"
                                />
                            )}
                        </div>
                    </div>
                    {playerInfo?.team_short_name} ({playerInfo?.league_code})
                    <div className="traits flex gap-1 mt-5">
                        {playerInfo?.hot > 0 && (
                            <div className="text-[#02B059] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#02B05923] ">
                                High Performer
                            </div>
                        )}
                        {playerInfo?.cold > 0 && (
                            <div className="text-[#FA4265] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#FA426523] ">
                                Low Performer
                            </div>
                        )}

                        {playerInfo?.prospect > 0 && (
                            <div className="text-[#02B059] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#02B05923] ">
                                Young Talent
                            </div>
                        )}
                        {playerInfo?.underused > 0 && (
                            <div className="text-[#F29445] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#F2944523] ">
                                Underused
                            </div>
                        )}
                        {playerInfo?.breakout > 0 && (
                            <div className="text-[#F29445] text-sm font-semibold px-2 py-1 rounded-[4px] bg-[#F2944523] ">
                                First Season
                            </div>
                        )}
                    </div>
                </div>

                <div className="flex justify-end items-center">
                    <div
                        id="shortlist"
                        className="bg-white hidden md:flex items-center input-border p-2 lg:p-3 min-[1920px]:p-3 rounded-lg outline-none"
                    >
                        {checkSavePlayer(
                            playerShortListData,
                            playerInfo?.name_dob
                        ) ? (
                            <img
                                className="cursor-pointer"
                                onClick={() => handleAddOrRemove(playerInfo)}
                                src={searchBlue}
                                alt="icon"
                            />
                        ) : (
                            <img
                                className="cursor-pointer"
                                onClick={() => handleAddOrRemove(playerInfo)}
                                src={searchFilter}
                                alt="icon"
                            />
                        )}
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-4 gap-2 w-full rounded-lg p-3 shadow-lg">
                        <>
                            <Season
                                position={positionSelected}
                                seasonSelected={seasonSelected}
                                setSeasonSelected={setSeasonSelected}
                                // new
                                setPositionSelected={setPositionSelected}
                                setLeagueSelected={setLeagueSelected}
                                setTeamSelected={setTeamSelected}
                                finalData={finalData}
                                setFirstClick={setFirstClick}
                                setPlayerInfo={setPlayerInfo}
                            />
                            <Position
                                positionSelected={positionSelected}
                                setPositionSelected={setPositionSelected}
                                playerInfo={playerInfo}
                                // new
                                season={seasonSelected}
                                seasonSelected={seasonSelected}
                                setLeagueSelected={setLeagueSelected}
                                setTeamSelected={setTeamSelected}
                                finalData={finalData}
                                setFirstClick={setFirstClick}
                                setPlayerInfo={setPlayerInfo}
                            />
                            <League
                                // playerName={playerName}
                                leagueSelected={leagueSelected}
                                setLeagueSelected={setLeagueSelected}
                                position={positionSelected}
                                season={seasonSelected}
                                // new
                                setTeamSelected={setTeamSelected}
                                finalData={finalData}
                                setFirstClick={setFirstClick}
                                setPlayerInfo={setPlayerInfo}
                            />
                            <Team
                                teamSelected={teamSelected}
                                setTeamSelected={setTeamSelected}
                                position={positionSelected}
                                season={seasonSelected}
                                league={leagueSelected}
                                finalData={finalData}
                                setFirstClick={setFirstClick}
                                setPlayerInfo={setPlayerInfo}
                            />
                        </>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FilterArea;
