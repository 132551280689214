import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import ProfileInfo from "./ProfileInfo";
import { makePosition } from "../../customFunctions/makePosition";
import plus from "../../Images/plus.png";
import minus from "../../Images/minus.png";
import { BiSearch } from "react-icons/bi";
import { debounce } from "lodash";
import playersData from "../../players.json";
import contentLoader from "../../Images/loader.gif";
import { DataContext } from "../../Context/DataProvider";
import { Link, useNavigate, useParams } from "react-router-dom";

const PlayerProfile2 = () => {
  const [searchedText, setSearchedText] = useState("");
  const [players, setPlayers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [mainContent, setMainContent] = useState(false);
  const [playerInfo, setPlayerInfo] = useState({});
  const [playerName, setPlayerName] = useState("");
  const [infoLoading, setInfoLoading] = useState(false);
  const [positionSelected, setPositionSelected] = useState("");
  const [leagueSelected, setLeagueSelected] = useState("");
  const [seasonSelected, setSeasonSelected] = useState("");
  const [teamSelected, setTeamSelected] = useState("");
  const [contentLoading, setContentLoading] = useState(false);
  const [playerAllDetails, setPlayerAllDetails] = useState({});
  const [finalData, setFinalData] = useState([]);
  const [latestSeason, setLatestSeason] = useState("");
  const [firstClick, setFirstClick] = useState(false);
  const [playerHistory, setPlayerHistory] = useState([]);
  const [nameLength, setNameLength] = useState(null);
  const [fixHeight, setFixHeight] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [sortedDataViaPosition, setSortedDataViaPosition] = useState([]);
  const [playerApiCall, setPlayerApiCall] = useState(false);
  const [leagueTableData, setLeagueTableData] = useState(null);

  const { base_url, token } = useContext(DataContext);

  const navigate = useNavigate();

  const { name_dob } = useParams();
  const influenceData = [
    {
      name: "Defending Impact",
      number: playerInfo?.def_qual ? playerInfo.def_qual : 0,
    },
    {
      name: "Defending Frequency",
      number: playerInfo?.def_quant ? playerInfo.def_quant : 0,
    },
    {
      name: "Ball Retention",
      number: playerInfo?.ball_ret ? playerInfo.ball_ret : 0,
    },
    {
      name: "Shot Creation",
      number: playerInfo?.attack_sc ? playerInfo.attack_sc : 0,
    },
    {
      name: "Ball Progression",
      number: playerInfo?.attack_bp ? playerInfo.attack_bp : 0,
    },
  ];

  const frequency = [
    {
      name: "Aerial",
      value: playerInfo?.aerial,
    },
    {
      name: "Dribbling",
      value: playerInfo?.carry,
    },
    {
      name: "Box Receptions",
      value: playerInfo?.receive,
    },
    {
      name: "Shooting",
      value: playerInfo?.shoot,
    },
    {
      name: "Recovering",
      value: playerInfo?.recover,
    },
    {
      name: "Disrupting",
      value: playerInfo?.disrupt,
    },
    {
      name: "Crossing",
      value: playerInfo?.cross,
    },
    {
      name: "Link-up passing",
      value: playerInfo?.link,
    },
    {
      name: "Forward passing",
      value: playerInfo?.forward,
    },
    {
      name: "Short Passes",
      value: playerInfo?.pass1,
    },
    {
      name: "Medium Passes",
      value: playerInfo?.pass2,
    },
    {
      name: "Long Passes",
      value: playerInfo?.pass3,
    },
  ];

  let selectPosition;
  if (positionSelected === "position") {
    selectPosition = "";
  } else {
    selectPosition = positionSelected;
  }

  // get table data for selected league
    useEffect(() => {
    fetch(`${base_url}/api/v1/player/ranking/popup?season=${seasonSelected}&position=${positionSelected}&league_code=${playerInfo?.league_code}`)
      .then((res) => res.json())
      .then((data) => {
        setLeagueTableData(data);
      });
  }, [seasonSelected, positionSelected ,leagueSelected]);


  // getting all data when a player is searched and clicked
  useEffect(() => {
    if ((playerName !== "" || name_dob !== "") && playerApiCall) {
      setContentLoading(true);
      fetch(`${base_url}/api/v1/player/details?name_dob=${name_dob}`)
        .then((res) => res.json())
        .then((data) => {
          setPlayerAllDetails(data);
          setPlayerApiCall(false);
          setLoading(false);
          setContentLoading(false);
        });
    }
  }, [name_dob, playerApiCall, playerName]);

  useEffect(() => {
    if (name_dob !== "") {
      handlePlayerDetail(name_dob);
    }
  }, [name_dob]);

  // making sorted api data to an array
  useEffect(() => {
    if (playerAllDetails !== undefined && playerName !== "") {
      const result = Object.values(playerAllDetails);

      const newData = [];
      result.length &&
        result.map((data) => data.map((item) => newData.push(item)));

      if (newData.length) {
        setFinalData(newData);
      }
    }
  }, [playerAllDetails, playerName]);

  // getting player info by latest season and then getting latest minute data and setup into selected position,season,league,team
  useEffect(() => {
    setInfoLoading(true);

    if (finalData.length && firstClick) {
      if (finalData.length) {
        const newestSeasonObj = finalData?.find((data) =>
          Math.max.apply(data.season)
        );

        setLatestSeason(newestSeasonObj?.season);
        if (latestSeason !== "") {
          const arrByLatestSeason = finalData?.filter(
            (data) => data?.season === latestSeason
          );

          let maxObjectByMInutes = null;
          let maxValue = Number.NEGATIVE_INFINITY;

          for (const obj of arrByLatestSeason) {
            if (Number(obj.minutes) > maxValue) {
              maxObjectByMInutes = obj;
              maxValue = Number(obj.minutes);
            }
          }
          const player = maxObjectByMInutes;

          setPlayerInfo(player);
          setPositionSelected(player?.position);
          setSeasonSelected(player?.season);
          setLeagueSelected(player?.league);
          setTeamSelected(player?.team_short_name);
        }
      }
    }
    setInfoLoading(false);
  }, [finalData, latestSeason, firstClick]);

  // get data for history
  useEffect(() => {
    if (finalData.length) {
      const arrByPosition = finalData?.filter(
        (data) => data?.position === selectPosition
      );

      setPlayerHistory(arrByPosition);
    }
  }, [finalData, selectPosition]);

  useEffect(() => {
    if (searchedText !== "") {
      setLoading(true);

      const searchWords = searchedText.trim().split(/\s+/);
      const filteredResults = playersData.filter((player) => {
        const { name_team } = player;
        const lowerCaseName = name_team.toLocaleLowerCase();

        return searchWords.every((word) =>
          lowerCaseName.includes(word.toLowerCase())
        );
      });

      setPlayers(filteredResults.slice(0, 10));
      setLoading(false);
      setNotFound(false);
    } else {
      setLoading(false);
    }
  }, [searchedText]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".player-list") &&
        !e.target.closest(".search-box")
      ) {
        setSearchDropdown(false);
      }
    });
  }, []);

  const handlePlayerDetail = (name) => {
    setPlayerApiCall(true);
    setPlayerName(name);
    setContentLoading(true);
    setSearchedText("");
    setSearchDropdown(false);
    setMainContent(true);
    setPositionSelected("position");
    setLeagueSelected("league");
    setSeasonSelected("season");
    setTeamSelected("team");
    setFirstClick(true);
    setFixHeight(false);

    // navigate();
  };

  useEffect(() => {
    if (finalData.length > 0) {
      const result = [...finalData].sort((a, b) => {
        if (
          Number(a.career_games_in_position) >
          Number(b.career_games_in_position)
        ) {
          return -1;
        }
        if (
          Number(a.career_games_in_position) <
          Number(b.career_games_in_position)
        ) {
          return 1;
        }
        return 0;
      });
      const uniqueArray = [];
      const positions = [];

      result.forEach((obj) => {
        if (!positions.includes(obj.position)) {
          positions.push(obj.position);
          uniqueArray.push(obj);
        }
      });
      setSortedDataViaPosition(uniqueArray?.slice(0, 6));
    }
  }, [finalData]);

  console.log("pos", sortedDataViaPosition);

  useEffect(() => {
    if (searchedText === "") {
      setPlayers(null);
    }
  }, [searchedText]);

  if (contentLoading) {
    return (
      <div
        id="preloader"
        className="d-flex align-items-center justify-content-center"
      >
        <span class="text-center">
          <img
            className="mx-auto d-block"
            width="300"
            alt="loader"
            src={contentLoader}
          />
        </span>
      </div>
    );
  }

  const handleNavigateProfile = () => {
    setContentLoading(true);
    setPlayerAllDetails([]);
    console.log(contentLoading);
  };
  // console.log(playerAllDetails);

  const text = (e) => {
    setSearchedText(e.target.value);
    setSearchDropdown(true);
  };
  const debounceOnChange = debounce(text, 400);

  let content;
  if (loading && players === null && searchedText.length > 0) {
    content = <progress className="progress loading w-56"></progress>;
  }

  if (players?.length === 0 && searchedText.length > 0) {
    content = <p className="not-found">No data found !</p>;
  }

  if (contentLoading) {
    <div id="preloader" className="flex items-center justify-center">
      <div className="flex loader-box justify-center items-center ">
        <span className="text-center">
          <img
            className="mx-auto d-block"
            alt="loader"
            width="50"
            src={contentLoader}
          />
        </span>
      </div>
    </div>;
  }

  if (
    players?.length > 0 &&
    !loading &&
    players !== null &&
    searchedText.length > 0 &&
    searchDropdown === true
  ) {
    content = (
      <ul className=" w-[84%] md:w-[28.5%] min-[1920px]:w-[20.8%] absolute mt-[0px] md:mt-[50px] border rounded-[15px]  max-h-[350px] overflow-y-scroll z-50 shadow-lg bg-base-100 p-2 ">
        <h6 className="text-xs text-neutral-content mt-2 mb-1 ml-3">PLAYERS</h6>
        {players?.map((player, index) => (
          <li key={index} onClick={() => handlePlayerDetail(player.name_dob)}>
            <div className="p-3 pt-2">
              <div
                onClick={handleNavigateProfile}
                className="text-sm cursor-pointer"
              >
                <Link to={`/profile/${player.name_dob}`}>
                  <h6 className="text-semibold">{player.name_dob}</h6>
                  <p className="text-neutral-content text-medium">
                    {player.age} yr • {player.team}
                  </p>
                </Link>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  console.log(playerInfo);

  let finalRangeValue;
  if (playerInfo?.position_rank === null) {
    finalRangeValue = 0;
  } else {
    finalRangeValue =
      100 -
      (Number(playerInfo?.position_rank) /
        Number(playerInfo?.position_rank_max)) *
        100;
  }
  return (
    <div className=" flex flex-col md:flex-row gap-5 pt-5 md:pt-10 pb-5 px-5 md:px-10 min-h-screen">
      {playerHistory?.length > 0 ? (
        <>
          <div className="basis-full md:basis-[40%]">
            <Sidebar
              playerInfo={playerInfo}
              positionData={sortedDataViaPosition}
              content={content}
              debounceOnChange={debounceOnChange}
              positionSelected={positionSelected}
              setPositionSelected={setPositionSelected}
              seasonSelected={seasonSelected}
              setSeasonSelected={setSeasonSelected}
              leagueSelected={leagueSelected}
              setLeagueSelected={setLeagueSelected}
              teamSelected={teamSelected}
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
              position={positionSelected}
            />
            <ProfileInfo
              playerDetails={playerHistory}
              positionData={sortedDataViaPosition}
              playerInfo={playerInfo}
              leagueData={leagueTableData}
              selectedSeason={seasonSelected}
              selectedPosition={positionSelected}
              selectedLeague={leagueSelected}
            />
          </div>
          <div className="basis-full md:basis-[60%]">
            <div className="bg-base-100 rounded-lg shadow-lg">
              <div className="text-[#22272F] border-b-[1.5px] border-[#F2F3F6] px-6 py-4 text-base font-semibold  ">
                Player Performance
              </div>
              <div className="flex flex-col md:flex-row ">
                <div className="basis-full md:basis-1/3 p-3 md:p-4 ">
                  <h4 className="text-[#7C86A1] text-xs font-semibold ">
                    INFLUENCE ON GAMES
                  </h4>
                  <div className="mt-2 flex flex-col gap-3 md:gap-4 ">
                    {influenceData?.map((item) => (
                      <div className="">
                        <div className="flex justify-between items-center">
                          <div className="text-[#9299AA] text-base md:text-sm min-[1350px]:text-base font-[500] ">
                            {item?.name}
                          </div>
                          <div className="text-[#22272F] text-sm font-[600] ">
                            {item?.number}
                          </div>
                        </div>
                        <div
                          className={`mt-[-8px] ${
                            Number(item?.number) < 34 && "prog-low"
                          } ${
                            item.number > 33 &&
                            item.number < 66 &&
                            "prog-middle"
                          } ${item.number > 65 && "prog-high"}`}
                        >
                          <progress
                            className={`progress w-full`}
                            value={item.number}
                            max="100"
                          ></progress>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="basis-full md:basis-1/3 flex flex-col justify-between p-3 md:p-4  border-t-[1.5px] md:border-x-[1.5px] md:border-t-[1.5px] border-[#F2F3F6] ">
                  {/* skills */}
                  <div>
                    <h4 className="text-[#7C86A1] text-xs font-semibold ">
                      SKILLS
                    </h4>
                    <div className="mt-2 flex flex-col gap-3 ">
                      <div className="flex justify-between items-center ">
                        <div className="text-[#22272F] text-base font-[500] ">
                          Dribbling
                        </div>
                        <div
                          className={` ${
                            playerInfo?.dribble < 34 &&
                            playerInfo?.dribble > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.dribble > 33 &&
                            playerInfo?.dribble < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.dribble > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.dribble == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[4px] flex justify-center items-center w-[37px] h-[28px]  text-base font-semibold`}
                        >
                          {playerInfo?.dribble > 0 ? playerInfo?.dribble : "-"}
                        </div>
                      </div>
                      <div className="flex justify-between items-center ">
                        <div className="text-[#22272F] text-base font-[500] ">
                          Aerial
                        </div>
                        <div
                          className={` ${
                            playerInfo?.open < 34 &&
                            playerInfo?.open > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.open > 33 &&
                            playerInfo?.open < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.open > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.open == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[4px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[600]`}
                        >
                          {playerInfo?.open > 0 ? playerInfo?.open : "-"}
                        </div>
                      </div>
                      <div className="flex justify-between items-center ">
                        <div className="text-[#22272F] text-base font-[500] ">
                          Tackling
                        </div>
                        <div
                          className={` ${
                            playerInfo?.tackle < 33 &&
                            playerInfo?.tackle > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.tackle > 33 &&
                            playerInfo?.tackle < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.tackle > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.tackle == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[4px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[600]`}
                        >
                          {playerInfo?.tackle > 0 ? playerInfo?.tackle : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* finishing */}
                  <div>
                    <h4 className="text-[#7C86A1] text-xs mt-3 font-semibold ">
                      FINISHING
                    </h4>
                    <div className="mt-3 flex flex-col gap-3 ">
                      <div className="flex justify-between items-center ">
                        <div className="text-[#22272F] text-base font-[500] ">
                          Foot{" "}
                          <span className="text-[#9299AA]">(Open Play)</span>
                        </div>
                        <div
                          className={` ${
                            playerInfo?.open_foot < 33 &&
                            playerInfo?.open_foot > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.open_foot > 33 &&
                            playerInfo?.open_foot < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.open_foot > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.open_foot == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-semibold`}
                        >
                          {playerInfo?.open_foot > 0
                            ? playerInfo.open_foot
                            : "-"}
                        </div>
                      </div>
                      <div className="flex justify-between items-center ">
                        <div className="text-[#22272F] text-base font-[500] ">
                          Head{" "}
                          <span className="text-[#9299AA]">(Open Play)</span>
                        </div>
                        <div
                          className={` ${
                            playerInfo?.open_head < 33 &&
                            playerInfo?.open_head > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.open_head > 33 &&
                            playerInfo?.open_head < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.open_head > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.open_head == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[600]`}
                        >
                          {playerInfo?.open_head > 0
                            ? playerInfo.open_head
                            : "-"}
                        </div>
                      </div>
                      <div className="flex justify-between items-center ">
                        <div className="text-[#22272F] text-base font-[500] ">
                          Foot{" "}
                          <span className="text-[#9299AA]">(Set Play)</span>
                        </div>
                        <div
                          className={` ${
                            playerInfo?.dead_foot < 33 &&
                            playerInfo?.dead_foot > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.dead_foot > 33 &&
                            playerInfo?.dead_foot < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.dead_foot > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.dead_foot == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-base font-[600]`}
                        >
                          {playerInfo?.dead_foot > 0
                            ? playerInfo.dead_foot
                            : "-"}
                        </div>
                      </div>
                      <div className="flex justify-between items-center ">
                        <div className="text-[#22272F] text-base font-[500] ">
                          Head{" "}
                          <span className="text-[#9299AA]">(Set Play)</span>
                        </div>
                        <div
                          className={` ${
                            playerInfo?.dead_head < 33 &&
                            playerInfo?.dead_head > 0 &&
                            "text-[#fa4265] bg-[#fa426514] "
                          } ${
                            playerInfo?.dead_head > 33 &&
                            playerInfo?.dead_head < 66 &&
                            "text-[#F29445] bg-[#F2944514] "
                          } ${
                            playerInfo?.dead_head > 65 &&
                            "text-[#02b059] bg-[#02b05914] "
                          } ${
                            playerInfo?.dead_head == 0 &&
                            " bg-[#f7f7f8] text-[#22272F] "
                          } rounded px-[8px] py-[2px] flex justify-center items-center w-[37px] h-[28px]  text-sm md:text-base font-[600]`}
                        >
                          {playerInfo?.dead_head > 0
                            ? playerInfo.dead_head
                            : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="basis-full md:basis-1/3 p-0 md:p-4  ">
                  {/* {playerInfo?.position_rank ? (
                    <div className="text-[#22272F] text-sm 2xl:text-base font-[500]">
                      <span className="font-[600]">
                        {playerInfo?.name + " "}
                      </span>
                                            
                      <span className="text-[#9299AA]">ranks</span>{" "}
                      <span className="font-[600]">
                        {playerInfo?.position_rank
                          ? makePosition(playerInfo?.position_rank)
                          : "-"}
                      </span>{" "}
                      <span className="text-[#9299AA]">best</span>{" "}
                      <span className="font-[600]">
                        <span className="font-[600]">
                          {playerInfo?.position + " "}
                        </span>
                      </span>{" "}
                      <span className="text-[#9299AA]">in the</span>{" "}
                      <span className="font-[600]">
                        {playerInfo?.league + " "}
                      </span>
                      <span className="text-[#9299AA]"> based on</span>
                      <span className="font-[600]">
                        {" " + playerInfo?.minutes}
                      </span>{" "}
                      <span className="text-[#9299AA]">minutes</span>
                    </div>
                  ) : (
                    <div>
                      <div className="text-[#22272F] text-sm 2xl:text-base font-[500]">
                        <span className="font-[600]">
                          {playerInfo?.name + " "}
                        </span>
                        <span className="text-[#9299AA]">
                          has a Goal Impact Rating of
                        </span>{" "}
                        <span className="font-[600]">
                          {playerInfo?.x_gdar > 0
                            ? `+${playerInfo?.x_gdar}`
                            : playerInfo?.x_gdar}
                        </span>{" "}
                        <span className="text-[#9299AA]">
                          but due to his limited minutes at
                        </span>{" "}
                        <span className="font-[600]">
                          {playerInfo?.position + " "}
                        </span>
                        <span className="text-[#9299AA]">
                          {" "}
                          is not ranked yet
                        </span>
                      </div>
                    </div>
                    
                  )} */}
                  <div className="mt-0 hidden md:block ">
                    <div className="flex font-[600] justify-between items-center text-[#7C86A1] text-xs ">
                      <div>PROS</div>
                      <div>CONS</div>
                    </div>
                    <div className="flex justify-between ">
                      <div className=" text-[#22272F] text-base font-[500] mt-3  ">
                        {playerInfo?.pro?.split(",").map((value) => (
                          <div className="flex pros mb-2 gap-1 items-center ">
                            {playerInfo?.pro !== "" ? (
                              <>
                                <div className="rounded p-0.5 2xl:p-1 bg-[#02B05914]">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17"
                                    height="16"
                                    viewBox="0 0 17 16"
                                    fill="none"
                                  >
                                    <path
                                      d="M13.6665 8.71429H9.38079V13H7.95222V8.71429H3.6665V7.28571H7.95222V3H9.38079V7.28571H13.6665V8.71429Z"
                                      fill="#02B059"
                                    />
                                  </svg>
                                </div>
                                <div className="pro-text leading-[14px] ">
                                  {value}
                                </div>
                              </>
                            ) : (
                              <div className=""></div>
                            )}
                          </div>
                        ))}
                      </div>
                      <div className=" text-[#22272F] text-base font-[500] mt-3  ">
                        {playerInfo?.con?.split(",").map((value) => (
                          <div className="cons-box mb-2">
                            <div className="leading-[14px]">{value}</div>
                            <div className=" rounded p-0.5 2xl:p-1 bg-[#FA426514] ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path d="M13 9H3V7H13V9Z" fill="#FA4265" />
                              </svg>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* frequency */}
            <div className="bg-base-100 mt-6 hidden md:block rounded-lg shadow-lg">
              <div className="text-[#22272F] flex items-center border-b-[1.5px] border-[#F2F3F6] px-6 py-4 text-base font-semibold  ">
                Frequency{" "}
                <span className="ml-1 mt-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M12.149 9.475L11.519 10.119C11.015 10.623 10.7 11.05 10.7 12.1H9.3V11.75C9.3 10.973 9.615 10.273 10.119 9.769L10.987 8.887C11.246 8.635 11.4 8.285 11.4 7.9C11.4 7.5287 11.2525 7.1726 10.9899 6.91005C10.7274 6.6475 10.3713 6.5 10 6.5C9.6287 6.5 9.2726 6.6475 9.01005 6.91005C8.7475 7.1726 8.6 7.5287 8.6 7.9H7.2C7.2 7.15739 7.495 6.4452 8.0201 5.9201C8.5452 5.395 9.25739 5.1 10 5.1C10.7426 5.1 11.4548 5.395 11.9799 5.9201C12.505 6.4452 12.8 7.15739 12.8 7.9C12.799 8.49027 12.565 9.05628 12.149 9.475ZM10.7 14.9H9.3V13.5H10.7M10 3C9.08075 3 8.17049 3.18106 7.32122 3.53284C6.47194 3.88463 5.70026 4.40024 5.05025 5.05025C3.7375 6.36301 3 8.14348 3 10C3 11.8565 3.7375 13.637 5.05025 14.9497C5.70026 15.5998 6.47194 16.1154 7.32122 16.4672C8.17049 16.8189 9.08075 17 10 17C11.8565 17 13.637 16.2625 14.9497 14.9497C16.2625 13.637 17 11.8565 17 10C17 6.129 13.85 3 10 3Z"
                      fill="#9299AA"
                    />
                  </svg>
                </span>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-3 min-[1550px]:grid-cols-4 gap-x-[30px] min-[1350px]:gap-x-[50px] min-[1750px]:gap-x-[110px] min-[1920px]:gap-x-[125px] gap-y-2.5 md:gap-y-4 p-3 md:p-4">
                {frequency?.map((item) => (
                  <div className="flex justify-between gap-3 items-center ">
                    <div className="text-[#22272F] text-base font-[500] ">
                      {item.name}
                    </div>
                    <div>
                      <svg
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          y="6"
                          width="4"
                          height="16"
                          rx="1"
                          fill={item.value > 0 ? "#2C56EB" : "#9299AA"}
                          fill-opacity={item.value > 0 ? 1 : 0.32}
                        />
                        <rect
                          x="6"
                          y="6"
                          width="4"
                          height="16"
                          rx="1"
                          fill={item.value > 20 ? "#2C56EB" : "#9299AA"}
                          fill-opacity={item.value > 20 ? 1 : 0.32}
                        />
                        <rect
                          x="12"
                          y="6"
                          width="4"
                          height="16"
                          rx="1"
                          fill={item.value > 40 ? "#2C56EB" : "#9299AA"}
                          fill-opacity={item.value > 40 ? 1 : 0.32}
                        />
                        <rect
                          x="18"
                          y="6"
                          width="4"
                          height="16"
                          rx="1"
                          fill={item.value > 60 ? "#2C56EB" : "#9299AA"}
                          fill-opacity={item.value > 60 ? 1 : 0.32}
                        />
                        <rect
                          x="24"
                          y="6"
                          width="4"
                          height="16"
                          rx="1"
                          fill={item.value > 80 ? "#2C56EB" : "#9299AA"}
                          fill-opacity={item.value > 80 ? 1 : 0.32}
                        />
                      </svg>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-1/4 search-box relative text-sm mb-4 lg:mb-0">
            <input
              type="text"
              name=""
              // value={searchedText}
              onChange={debounceOnChange}
              className="w-full input-border p-3 pl-10 rounded-lg outline-none"
              placeholder="Search for a player"
            />

            <div className="absolute top-4 left-4 text-lg">
              <BiSearch />
            </div>
          </div>
          {content}
        </>
      )}
    </div>
  );
};

export default PlayerProfile2;
