import React, { useContext, useEffect, useState } from "react";
import CreatePlayerModal from "./CreatePlayerModal";
import { DataContext } from "../../Context/DataProvider";
import { useParams } from "react-router-dom";



const benchPlayers = [
  {
    number: "P1",
    name: "Luke Shaw",
    jerseyNumber: "23",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P2",
    name: "Scott Mc Tominay",
    jerseyNumber: "39",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P3",
    name: "Bruno Fernandes",
    jerseyNumber: "75",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P4",
    name: "Kevin De Bruyne",
    jerseyNumber: "78",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P5",
    name: "Bernardo Silva",
    jerseyNumber: "85",
    goal: "75",
    color: "#02B059",
  },
  {
    number: "P6",
    name: "Erling Haaland",
    jerseyNumber: "43",
    goal: "75",
    color: "#02B059",
  },
];




// console.log(getPlayerForPosition("USA","3_1_GK", fakeData)) ;

const TeamMembers = ({data, pitchData}) => {
  const { assignedPlayers } =
    useContext(DataContext);
    const {team} = useParams();
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [id, setId] = useState(null);
  const [playerType, setPlayerType] = useState(null);
  const [benchId, setBenchId] = useState(null);
  const [selectedPlayer, setSelectedPlayer] = useState(null);

  function getPlayerForPosition(teamName, id, data){
    const slTeam = data?.find(item=>item?.team===teamName);
     return slTeam?.players?.find(item=>item?.id===id);
  }

  function getBenchPlayer(teamName, id, data){
    
    
    const slTeam = data?.find(item=>item?.team===teamName);
     return slTeam?.benches?.find(item=>item?.id===id);
  }

  console.log("assign players",assignedPlayers);

  return (
    <div className="">
      <div className="first-team">
        <h2 className="title  mb-3">First Team</h2>
        {
          pitchData?.length>0 ? 
          <div>
           {pitchData?.map((member, index) => (
          <div
            className="flex justify-between items-center gap-3 mb-5 "
            key={index}
          >
            <div className="position p-5">
              <h4 className="px-5">{member?.position_abbreviation}</h4>
            </div>

            <label onClick={()=>{setPlayerType("normalPlayer") ; setId(member?.formation_id+"_"+member?.position_id+"_"+member?.position_abbreviation)}} htmlFor="create-new-player-modal" className="player cursor-pointer flex justify-between items-center w-2/4">
              
                <h4 className="text-neutral-content">{ getPlayerForPosition(team,member?.formation_id+"_"+member?.position_id+"_"+member?.position_abbreviation,assignedPlayers)?getPlayerForPosition(team,member?.formation_id+"_"+member?.position_id+"_"+member?.position_abbreviation,assignedPlayers).playerName:"Add new player "} </h4>
                
              
            </label>
            <div className="goal flex text-neutral justify-center items-center">
              <h4>
              { getPlayerForPosition(team,member?.formation_id+"_"+member?.position_id+"_"+member?.position_abbreviation,assignedPlayers)?getPlayerForPosition(team,member?.formation_id+"_"+member?.position_id+"_"+member?.position_abbreviation,assignedPlayers).x_gdar:"-"}
              </h4>
            </div>
          </div>
        ))}
          </div> :
          <div>
            <h4 className="title" >Loading.....</h4>
          </div>
        }
      </div>
      <div className="first-team mt-10">
        <h2 className="title  mb-3">Bench</h2>
        {benchPlayers.map((player, index) => (
          <div 
            onClick={()=>{setPlayerType("benchPlayer"); setSelectedPlayer(getBenchPlayer(team,"bench"+index,assignedPlayers)) ; setBenchId("bench"+player?.number)}}
            className="flex justify-between items-center gap-3 mb-5 "
            key={index}
          >
            <div className="position p-5">
              <h4 className="px-5">{player.number}</h4>
            </div>

            <label htmlFor="create-new-player-modal" className="player cursor-pointer text-neutral-content flex justify-between items-center w-2/4">
              <h4>{getBenchPlayer(team,"bench"+player?.number,assignedPlayers)?getBenchPlayer(team,"bench"+player?.number,assignedPlayers)?.playerName:"Add bench player"}</h4>
              
            </label>
            <div className="goal bg-[#f7f7f8] flex justify-center items-center">
              <h4>
              {getBenchPlayer(team,"bench"+player?.number,assignedPlayers)?getBenchPlayer(team,"bench"+player?.number,assignedPlayers)?.x_gdar:"-"}
              </h4>
            </div>
          </div>
        ))}
      </div>
      <CreatePlayerModal selectedPlayer={selectedPlayer} playerType={playerType} id={id} benchId={benchId} position={selectedPosition} />
    </div>
  );
};

export default TeamMembers;
