import React, { createContext, useEffect, useState } from "react";

export const DataContext = createContext(null);

const assignPlayersData = [
  {
    team:"USA",
    players:[
      {
        id: "3_1_GK",
        playerName:"test",
        createdTeam:"USA",
        season: 2017,
        position: "GK",
        team: "USA",
        league: "ARG1",
       },
       {
        id: "2_3_LM",
        playerName:"test2",
        createdTeam:"USA",
        season: 2018,
        position: "GK2",
        team: "USA2",
        league: "ARG12",
       },
    ],
    benches:[
      {
        id:"p1",
        playerName:"test",
        createdTeam:"USA",
        season: 2017,
        position: "GK",
        team: "USA",
        league: "ARG1",
      },
    ]
  },
  {
    team:"ARG",
    players:[
      {
        id: "3_1_GK",
        playerName:"test",
        createdTeam: "ARG",
        season: 2017,
        position: "GK",
        team: "USA",
        league: "ARG1",
       },
       {
        id: "2_3_LM",
        playerName:"test2",
        createdTeam: "ARG",
        season: 2018,
        position: "GK2",
        team: "USA2",
        league: "ARG12",
       },
    ]
  },
]

const DataProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [playerShortListData, setPlayerShortListData] = useState([]);
  const [coachShortListData, setCoachShortListData] = useState([]);
  const [playerSlLoading, setPlayerSlLoading] = useState(true);
  const [coachSlLoading, setCoachSlLoading] = useState(true);
  const [formationData, setFormationData] = useState(null);
  const [assignedPlayers, setAssignedPlayers] = useState([]);
  const [teams, setTeams] = useState([]);

  const token = localStorage.getItem("token");
  const base_url = "https://api.fenida.org";

  const image_base_url = "https://api.fenida.org";
  // const base_url = "http://127.0.0.1:8000";
  const user_league_mapping = {
    "Bochum-1848@Bochum-1848.com": "GER1",
    "Barcelona@Barcelona.com": "SPA1",
    "Liverpool@Liverpool.com": "ENG1",
    "Inter-Miami@Inter-Miami.com": "USA1",
    'ger2_hansa@gmail.com': 'GER2',
    'george@fenida.org': 'ENG1',
    'connor.barrett@nottinghamforest.co.uk': 'ENG1',
    'r_wilson@nottinghamforest.co.uk': 'ENG1',
    'tom.stockwell@nottinghamforest.co.uk': 'ENG1',
    'kyriakos.dourekas@nottinghamforest.co.uk': 'ENG1',
    'evangelos.perperidis@nottinghamforest.co.uk': 'ENG1',
    'Luiz.rocha@sportagency.com.br': 'ENG1',
    'pedro@outfieldinc.com': 'ENG1',
    'Ahmed@mtaa.co': 'ENG1',
    'george.meincke@gmail.com': 'ENG1',
    'miltiadis.em@gmail.com': 'ENG1',
    'dane.murphy16@gmail.com': 'ENG1',
    'ytheodorou@olympiacos.org': 'ENG1',
    'teo.cruz@sportagency.com.br': 'ENG1',
    'monirul@gmail.com': 'ENG1',
    'Olympiakos-Piraeus@Olympiakos-Piraeus.com': 'GRE1', //olympiakos
    'Rio-Ave@Rio-Ave.com': 'POR1', //rio-ave
  }
  const user_team_mapping = {
    "Bochum-1848@Bochum-1848.com": "Bochum 1848",
    "Barcelona@Barcelona.com": "Barcelona",
    "Liverpool@Liverpool.com": "Liverpool",
    "Inter-Miami@Inter-Miami.com": "Inter Miami",
    'ger2_hansa@gmail.com': 'Hansa Rostock',
    'george@fenida.org': 'Nottingham Forest',
    'connor.barrett@nottinghamforest.co.uk': 'Nottingham Forest',
    'r_wilson@nottinghamforest.co.uk': 'Nottingham Forest',
    'tom.stockwell@nottinghamforest.co.uk': 'Nottingham Forest',
    'kyriakos.dourekas@nottinghamforest.co.uk': 'Nottingham Forest',
    'evangelos.perperidis@nottinghamforest.co.uk': 'Nottingham Forest',
    'Luiz.rocha@sportagency.com.br': 'Nottingham Forest',
    'pedro@outfieldinc.com': 'Nottingham Forest',
    'Ahmed@mtaa.co': 'Nottingham Forest',
    'george.meincke@gmail.com': 'Nottingham Forest',
    'miltiadis.em@gmail.com': 'Nottingham Forest',
    'dane.murphy16@gmail.com': 'Nottingham Forest',
    'ytheodorou@olympiacos.org': 'Nottingham Forest',
    'teo.cruz@sportagency.com.br': 'Nottingham Forest',
    'monirul@gmail.com': 'Nottingham Forest',
    'Olympiakos-Piraeus@Olympiakos-Piraeus.com': 'Olympiakos Piraeus',
    'Rio-Ave@Rio-Ave.com': 'Rio Ave',
  }

  useEffect(() => {
    setLoading(true);
    
    const getUser = async () => {
      await fetch(`${base_url}/api/user`)
        .then((response) => response.json())
        .then((json) => {
          if (json) {
            setUser(json);
            setLoading(false);
          } else {
            setUser(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          if (error) {
            setLoading(false);
            setUser(null);
          }
        });
    }

    if(token){
        getUser();
    }else {
      setUser(null);
      setLoading(false);
    }
  }, []);


  useEffect(() => {
    const getShortlist = async() =>{
        await fetch(`${base_url}/api/v1/player/shortlist?user_id=${user?.id}`)
          .then(res=>res.json())
          .then(data=>{
            if(data?.length>0){
              setPlayerShortListData(data);
              setPlayerSlLoading(false);   
            }else if(data.length===0){
              setPlayerSlLoading(false);
            }
          })
    }
    if(user?.email ){
        getShortlist();
      // .catch(error=>{
      //   // if(error){
      //   //   alert("error from server");
      //   // }
      //   if(error){
      //     console.log("short list server error");
      //   }
      // })
    }else{
        
    }
  }, [user]);


  useEffect(() => {
    const getShortlist = async() =>{
        await fetch(`${base_url}/api/v1/coach/shortlist?user_id=${user?.id}`)
          .then(res=>res.json())
          .then(data=>{
           
            if(data?.length>0){
              setCoachShortListData(data);
              setCoachSlLoading(false);   
            }else if(data.length===0){
              setCoachSlLoading(false);
            }
          })
    }
    if(user?.email ){
        getShortlist();
     
    }else{
        
    }
  }, [user]);

  // team fetch
  useEffect(() => {
    const getTeams = async() =>{
        await fetch(`${base_url}/api/v1/myteam?user_id=${user?.id}`)
          .then(res=>res.json())
          .then(data=>{
           
            if(data?.length>0){
              setTeams(data)   
            }
          })
    }
    if(user?.email ){
        getTeams();
     
    }else{
        
    }
  }, [user]);

  // player fetch
  useEffect(() => {
    const getPlayers = async() =>{
        await fetch(`${base_url}/api/v1/team_players?user_id=${user?.id}`)
          .then(res=>res.json())
          .then(data=>{
           
            if(data?.length>0){
              setAssignedPlayers(data)   
            }
          })
    }
    if(user?.email ){
        getPlayers();
     
    }else{
        
    }
  }, [user]);

  // fetch formation data
  useEffect(() => {
    const getFormationData = async() =>{
        await fetch(`${base_url}/api/v1/formations`)
          .then(res=>res.json())
          .then(data=>{
           
            if(data?.length>0){
              setFormationData(data)   
            }
          })
    }
    if(user?.email ){
        getFormationData();
     
    }else{
        
    }
  }, [user]);


    // useEffect(() => {
    //     // setContentLoading(true);
    
    //     fetch(`${base_url}/api/v1/formations`)
    //       .then((res) => res.json())
    //       .then((data) => {
    //         setFormationData(data);
           
    //       })
    //       .catch((error) => {
    //         if (error) {
    //           alert("error from server");
    //         }
    //       });
    //   }, []);




  const value = {
    user,
    setUser,
    loading,
    setLoading,
    loginSuccess,
    setLoginSuccess,
    token,
    base_url,
    playerShortListData,
    setPlayerShortListData,
    playerSlLoading,
    coachShortListData,
    setCoachShortListData,
    coachSlLoading,
    formationData,
    setFormationData,
    assignedPlayers,
    setAssignedPlayers,
    teams,
    setTeams,
    user_league_mapping,
    user_team_mapping
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;
