import React from "react";
import arrow from "../../Images/arrow.png";
import "./PlayerProfile2.css";

const HistoryTable = ({ playerDetails }) => {
  // console.log(playerDetails);
  return (
    <>
      <div className="mt-3 hidden md:block">
        <div>
          <div className="pt-4 player-profile font-semibold">
            <table className="table w-full">
              {/* head */}
              <thead className="text-neutral-content">
                <tr>
                  <th>SEASON</th>
                  <th>
                    <div className="ml-[-15px]">TEAM</div>
                  </th>
                  <th className="text-center">LEAGUE</th>
                  <th className="text-center">MINUTES</th>
                  <th className="text-center">OFF</th>
                  <th className="text-center">DEF</th>
                  <th className="text-center">TOTAL</th>
                </tr>
              </thead>
              <tbody>
                {playerDetails?.map((history, index) => (
                  <tr
                    key={index}
                    className={` ${
                      index !== playerDetails?.length - 1
                        ? "border-b-[1.5px] border-[#F2F3F6] "
                        : ""
                    } text-base text-[#9299AA] font-medium `}
                  >
                    <td className="">
                      <div className="ml-0.5">
                        {history?.season.split("-").length !== 1
                          ? history.season.split("-")[0]
                          : history.season}
                      </div>
                    </td>
                    <td>
                      <div className="text-[#22272F] font-semibold ">
                        {history?.team_short_name}
                      </div>
                    </td>
                    <td className="text-center">
                      <div>{history?.league_code}</div>
                    </td>
                    <td className="text-center">
                      <div className="text">{history?.minutes}</div>
                    </td>
                    <td className="text-center">
                      <div className="text-[#22272F] font-semibold rounded px-2 py-1 bg-[#F7F7F8] ">
                        {history?.x_gfar
                          ? history?.x_gfar > 0
                            ? "+" + history.x_gfar
                            : history.x_gfar
                          : "0"}
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="text-[#22272F] font-semibold rounded px-2 py-1 bg-[#F7F7F8] ">
                        {history?.x_gaar
                          ? history?.x_gaar > 0
                            ? "+" + history.x_gaar
                            : history.x_gaar
                          : "0"}
                      </div>
                    </td>
                    <td className="text-center">
                      <div
                        className={` ${
                          history?.x_gdar > 0
                            ? "text-[#02B059] bg-[#02B05923]"
                            : history?.x_gdar < 0
                            ? "text-[#FA4265] bg-[#FA426523] "
                            : "text-[#22272F]"
                        } px-2 py-1 font-semibold rounded  `}
                      >
                        {history?.x_gdar
                          ? history?.x_gdar > 0
                            ? "+" + history.x_gdar
                            : history.x_gdar
                          : "0"}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* table for mobile */}

      <div className="pt-4 mt-3 hidden profile-table-mobile font-semibold">
        <table className="table w-full">
          {/* head */}
          <thead className="text-neutral-content">
            <tr>
              <th className="text-center w-[10%] ">
                <div className="">S</div>
              </th>
              <th className="w-[130px]">
                <div className="">TEAM</div>
              </th>
              <th className="text-center"> </th>
              <th className="text-center">MIN</th>
              {/* <th className="text-center">OFF</th> */}
              {/* <th className="text-center">DEF</th> */}
              <th className="text-end">TOTAL</th>
            </tr>
          </thead>
          <tbody>
            {playerDetails?.map((history, index) => (
              <tr
                key={index}
                className={` ${
                  index !== playerDetails?.length - 1
                    ? "border-b-[1.5px] border-[#F2F3F6] "
                    : ""
                } text-base text-[#9299AA] font-medium `}
              >
                <td className="text-center">
                  {history?.season.split("-").length !== 1
                    ? history.season.split("-")[0]
                    : history.season}
                </td>
                <td>
                  <div className="text-[#22272F] w-[130px] whitespace-normal leading-[16px] font-semibold ">
                    {history?.team_short_name}
                  </div>
                </td>
                <td className="text-center">
                  <div>{history?.league_code}</div>
                </td>
                <td className="text-center">
                  <div className="text">{history?.minutes}</div>
                </td>
                {/* <td className="text-center">
                      <div className="text-[#22272F] font-semibold w-[45px] rounded px-1.5 py-0.5 bg-[#F7F7F8] ">
                      {history?.x_gfar
                          ? history?.x_gfar > 0
                            ? "+" + history.x_gfar
                            : history.x_gfar
                          : "0"}
                      </div>
                    </td>
                    <td className="text-center">
                      <div className="text-[#22272F] font-semibold w-[45px] rounded px-.5 py-0.5 bg-[#F7F7F8] ">
                        {history?.x_gaar
                          ? history?.x_gaar > 0
                            ? "+" + history.x_gaar
                            : history.x_gaar
                          : "0"}
                      </div>
                    </td> */}
                <td className="">
                  <div
                    className={` flex ml-auto justify-center ${
                      history?.x_gdar > 0
                        ? "text-[#02B059] bg-[#02B05923]"
                        : history?.x_gdar < 0
                        ? "text-[#FA4265] bg-[#FA426523] "
                        : "text-[#22272F]"
                    } px-1.5 py-0.5 font-semibold w-[45px] rounded  `}
                  >
                    {history?.x_gdar
                      ? history?.x_gdar > 0
                        ? "+" + history.x_gdar
                        : history.x_gdar
                      : "0"}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HistoryTable;
