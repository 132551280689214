import React, { useContext, useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import player from "../../Images/sidebar-player.png";
import League from "./Dropdown/League";
import circle from "../../Images/point-circle.png";
import searchBlue from "../../Images/search-blue.png";
import searchFilter from "../../Images/search-filter.png";
import Season from "./Dropdown/Season";
import Position from "./Dropdown/Position";
import Team from "./Dropdown/Team";
import { DataContext } from "../../Context/DataProvider";

const Sidebar = ({
  playerInfo,
  content,
  debounceOnChange,
  positionSelected,
  setPositionSelected,
  seasonSelected,
  setSeasonSelected,
  leagueSelected,
  setLeagueSelected,
  teamSelected,
  setTeamSelected,
  finalData,
  setFirstClick,
  setPlayerInfo,
  positionData,
}) => {
  const { base_url, token, user, playerShortListData, setPlayerShortListData } =
    useContext(DataContext);
  const [searchbar, setSearchbar] = useState(false);

  return (
    <div>
      <div className=" hidden md:flex gap-2 rounded-lg p-3 bg-white shadow-lg flex-wrap ">
        {!searchbar && (
          <div className="hidden md:block">
            <button
              onClick={() => setSearchbar(true)}
              className={`${"flex"} w-[44px] h-11 rounded-lg border justify-center items-center searchBtn`}
            >
              <BiSearch className="text-lg" />
            </button>
          </div>
        )}
        {searchbar && (
          <div className={`relative text-sm mb-4 lg:mb-0`}>
            <input
              type="text"
              name=""
              onChange={debounceOnChange}
              className="min-w-[400px] input-border p-3 pl-10 rounded-lg outline-none"
              placeholder="Search for a player"
            />

            <div
              onClick={() => setSearchbar(false)}
              className="absolute cursor-pointer top-4 left-4 text-lg"
            >
              <BiSearch />
            </div>
          </div>
        )}
        <div className={`relative w-full md:hidden text-sm mb-4 lg:mb-0`}>
          <input
            type="text"
            name=""
            onChange={debounceOnChange}
            className="w-full input-border p-3 pl-10 rounded-lg outline-none"
            placeholder="Search for a player"
          />

          <div
            onClick={() => setSearchbar(false)}
            className="absolute cursor-pointer top-4 left-4 text-lg"
          >
            <BiSearch />
          </div>
        </div>
        {content}

        {!searchbar && (
          <>
            <Season
              position={positionSelected}
              seasonSelected={seasonSelected}
              setSeasonSelected={setSeasonSelected}
              // new
              setPositionSelected={setPositionSelected}
              setLeagueSelected={setLeagueSelected}
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <Position
              positionSelected={positionSelected}
              setPositionSelected={setPositionSelected}
              playerInfo={playerInfo}
              // new
              season={seasonSelected}
              seasonSelected={seasonSelected}
              setLeagueSelected={setLeagueSelected}
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <League
              // playerName={playerName}
              leagueSelected={leagueSelected}
              setLeagueSelected={setLeagueSelected}
              position={positionSelected}
              season={seasonSelected}
              // new
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <Team
              teamSelected={teamSelected}
              setTeamSelected={setTeamSelected}
              position={positionSelected}
              season={seasonSelected}
              league={leagueSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
          </>
        )}
      </div>

      {/* for mobile */}
      <div className="block md:hidden rounded-lg p-3 bg-white shadow-lg ">
        
        
        <div className={`relative w-full md:hidden text-sm mb-4 lg:mb-0`}>
          <input
            type="text"
            name=""
            onChange={debounceOnChange}
            className="w-full input-border p-3 pl-10 rounded-lg outline-none"
            placeholder="Search for a player"
          />

          <div
            onClick={() => setSearchbar(false)}
            className="absolute cursor-pointer top-4 left-4 text-lg"
          >
            <BiSearch />
          </div>
        </div>
        {content}

        <div className="grid grid-cols-2 gap-x-2 gap-y-4 ">
        <Season
              position={positionSelected}
              seasonSelected={seasonSelected}
              setSeasonSelected={setSeasonSelected}
              // new
              setPositionSelected={setPositionSelected}
              setLeagueSelected={setLeagueSelected}
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <Position
              positionSelected={positionSelected}
              setPositionSelected={setPositionSelected}
              playerInfo={playerInfo}
              // new
              season={seasonSelected}
              seasonSelected={seasonSelected}
              setLeagueSelected={setLeagueSelected}
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <League
              // playerName={playerName}
              leagueSelected={leagueSelected}
              setLeagueSelected={setLeagueSelected}
              position={positionSelected}
              season={seasonSelected}
              // new
              setTeamSelected={setTeamSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
            <Team
              teamSelected={teamSelected}
              setTeamSelected={setTeamSelected}
              position={positionSelected}
              season={seasonSelected}
              league={leagueSelected}
              finalData={finalData}
              setFirstClick={setFirstClick}
              setPlayerInfo={setPlayerInfo}
            />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
