import React, { useEffect, useState } from "react";
import arrow from "../../../Images/arrow.png";

const League = ({
  position,
  season,
  leagueSelected,
  setLeagueSelected,
  setTeamSelected,
  finalData,
  setFirstClick,
  setPlayerInfo,
}) => {
  const [leagueToggle, setLeagueToggle] = useState(false);
  const [leagueItems, setLeagueItems] = useState([]);

  useEffect(() => {
    if (finalData.length) {
      const leagues = [];
      finalData.forEach((item) => {
        if (item.position === position && item.season === season) {
          leagues.push(item.league);
        }
      });

      const arrData = Array.from(new Set(leagues));
      // const leagueFinalItems = arrData.filter(
      //     (data) => data !== leagueSelected
      // );
      setLeagueItems(arrData);
    }
  }, [finalData, position, season]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".league-dropdown") &&
        !e.target.closest(".league-btn")
      ) {
        setLeagueToggle(false);
      }
    });
  }, []);

  const setData = (league) => {
    let filterNeeded;

    filterNeeded = finalData?.filter(
      (data) =>
        data?.position === position &&
        data?.season === season &&
        data?.league === league
    );
    if (filterNeeded.length) {
      const newestSeasonObj = filterNeeded?.find((data) =>
        Math.max.apply(data.season)
      );

      const arrByLatestSeason = filterNeeded?.filter(
        (data) => data?.season === newestSeasonObj?.season
      );
      const arrByMaxMins = arrByLatestSeason?.filter((data) =>
        Math.max.apply(data?.minutes)
      );
      const player = arrByMaxMins[0];
      setPlayerInfo(player);

      setTeamSelected(player?.team_short_name);
    }
  };
  return (
    <div className="relative mt-3">
      <button
        onClick={() => setLeagueToggle(!leagueToggle)}
        className="w-full input-border p-3 rounded-lg outline-none league-btn flex justify-between items-center bg-base-100"
      >
        <p>{leagueSelected === undefined ? "league" : leagueSelected}</p>
        <img src={arrow} alt="" />
      </button>

      {leagueToggle && leagueItems.length > 0 && (
        <div className="absolute max-h-[250px] overflow-y-scroll league-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
          {leagueItems?.map((item, index) => (
            <p
              key={index}
              onClick={() => {
                setLeagueSelected(item);
                setTeamSelected("");
                setLeagueToggle(false);
                setFirstClick(false);
                setData(item);
              }}
              className="cursor-pointer p-1.5 hover:bg-[#F7F7F8] duration-300"
            >
              {item}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default League;
