import React, { useCallback, useEffect, useState } from "react";
import decreaseIcon from "../../Images/decrease.png";
import increaseIcon from "../../Images/increase.png";
import levelIcon from "../../Images/level.png";
import { BiSearch } from "react-icons/bi";

const LeaguesRanking = ({ data }) => {
  const [dataViaPage, setDataViaPage] = useState([]);
  const [newData, setNewData] = useState([]);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [searchText, setSearchText] = useState(null);
  const [selectedLeague, setSelectedLeague] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [notFound, setNotFound] = useState(false);


  // Step 1: Parse data and group by league_code
  const leagues = data?.reduce((acc, team) => {
    const { league_code, country, spi } = team;
    if (!acc[league_code]) {
      acc[league_code] = {
        country,
        teams: [],
      };
    }
    acc[league_code].teams.push(team);
    return acc;
  }, {});

  // Step 2: Calculate the median spi value for each league
  const leagueMedians = Object.entries(leagues).map(
    ([leagueCode, leagueData], index) => {
      const spis = leagueData.teams.map((team) => Number(team.spi));
      const sortedSpis = spis.sort((a, b) => a - b);
      const mid = Math.floor(sortedSpis.length / 2);
      const medianSpi =
        sortedSpis.length % 2 !== 0
          ? sortedSpis[mid]
          : (sortedSpis[mid - 1] + sortedSpis[mid]) / 2;
      return {
        leagueCode,
        country: leagueData.country,
        medianSpi,
        teams: leagueData.teams,
      };
    }
  );

  // Step 3: Sort the leagues by medianSpi in descending order
  leagueMedians.sort((a, b) => b.medianSpi - a.medianSpi);

  const leagueMediansWithSerialNo = leagueMedians.map((item, index) => ({
    ...item,
    serial_no: index + 1,
  }));

  const totalPage = Math.ceil(leagueMediansWithSerialNo?.length / 20);

  const getDataForPage = (pageNumber) => {
    const startIndex = (pageNumber - 1) * 20;
    const endIndex = Math.min(startIndex + 20, leagueMediansWithSerialNo.length);
    return leagueMediansWithSerialNo.slice(startIndex, endIndex);
  };

  const handlePageChange = (page) => {
    if (!page) {
      setDataViaPage(getDataForPage(1));
      setCurrentPage(1);
    }
    setDataViaPage(getDataForPage(Number(page)));
    setCurrentPage(Number(page));
  };

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".player-list") &&
        !e.target.closest(".search-box")
      ) {
        setSearchDropdown(false);
      }
    });
  }, []);

  const handleSearch = useCallback(() => {
    if (searchText) {
      const filteredData = leagueMediansWithSerialNo?.filter((d) =>
        d?.leagueCode.toLowerCase().includes(searchText.toLocaleLowerCase())
      );
      if(filteredData.length===0 && searchText ){
        setNotFound(true)
      }
      if(filteredData.length>0 && searchText ){
        setNotFound(false)
      }
      setNewData(filteredData);
    }
  }, [searchText]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handleSearch(searchText);
    }, 400);

    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [searchText, handleSearch]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value.trim());
    setSearchDropdown(true);
  };

  useEffect(() => {
    if (searchText === null || searchText === "") {
      setSearchDropdown(false);
      setNotFound(false);
      setNewData([]);
      setSelectedLeague(null);
      setDataViaPage(getDataForPage(Number(1)));
    }
  }, [searchText]);

  useEffect(()=>{
    if(currentPage>totalPage || currentPage<0){
      setDataViaPage(getDataForPage(Number(1)));
    }
  },[currentPage, totalPage])

  const handleHighlightTeam = (team) => {
    setSearchDropdown(false);
    setSearchText(team?.leagueCode);
    setSelectedLeague(team?.leagueCode);
    let teamIndex;
    leagueMediansWithSerialNo?.forEach((obj, index) => {
      if (obj?.leagueCode === team?.leagueCode) {
        teamIndex = index + 1;
      }
    });
    const page = Math.ceil(Number(teamIndex) / 20);
    setCurrentPage(page);
    setDataViaPage(getDataForPage(Number(page)));
  };
    
  const handleReset = () => {
    setSearchText("");
    setCurrentPage("");
    setNotFound(false);
  };

  let newContent;

  if (newData.length > 0 && searchDropdown) {
    newContent = (
      <ul className=" player-list max-h-[120px]  overflow-y-scroll b w-full z-50 shadow-lg bg-base-100 p-2 rounded-box">
        {newData?.map((league, index) => (
          <li key={index} onClick={() => handleHighlightTeam(league)}>
            <div className="p-3 pt-2">
              <div className="text-sm cursor-pointer">
                <h6 className="text-semibold">{league.leagueCode}</h6>
              </div>
            </div>
          </li>
        ))}
      </ul>
    );
  }

  

  

  return (
    <div className="teams-ranking w-1/3">
      <div className="ranking-header p-5 flex justify-between items-center ">
        <h3>Leagues Rankings</h3>
        <button disabled={searchText==="" || searchText===null} onClick={handleReset} className="btn-text">Reset</button>
      </div>
      <div className="ranking-table p-2">
        <div className="flex gap-3">
          <div className="w-4/6  search-box relative text-sm mb-3">
            <input
              type="text"
              name=""
              value={searchText}
              onChange={handleSearchChange}
              className="w-full input-border p-3 pl-10 rounded-lg outline-none"
              placeholder="Search for a league"
            />

            <button
              className="absolute cursor-pointer top-4 left-4 text-lg"
            >
              <BiSearch />
            </button>
          </div>
          <div className="w-3/6 flex gap-1 items-center search-box relative text-sm mb-3">
            <div className="w-2/5 text-xs ml-2 min-[1600px]:ml-12 ">
              Jump To :
            </div>

            <input
              onChange={(e) => {handlePageChange(e.target.value)}}
              value={currentPage}
              max={totalPage}
              type="text"
              placeholder={`1-${totalPage}`}
              className="input px-1 h-[40px] text-center input-border outline-none w-3/5"
            />
          </div>
        </div>
        {(notFound) && <div className="text-red-600 mb-3 ml-2 font-[600] " > No data found !!! </div> }
        {newContent}
        <div className="">
          <table className="table custom-table w-full">
            <tbody>
              {(dataViaPage?.length > 0
                ? dataViaPage
                : leagueMediansWithSerialNo?.slice(0, 20)
              )?.map(({ leagueCode,serial_no, country, medianSpi, teams }, index) => (
                <tr
                  className={`px-3 ${
                    (index + 1) % 2 !== 0 ? "bg-[#f2f3f6]" : ""
                  } ${leagueCode === selectedLeague && "highlighted-border"} `}
                  key={index}
                >
                  <td
                    align="center"
                    className="bg-transparent rounded-l-lg team-position"
                  >
                    {serial_no}.
                  </td>
                  <td className="bg-transparent team-name">
                    <div
                      className={`whitespace-normal ${!leagueCode && "ml-4"} `}
                    >
                      {leagueCode ? leagueCode : "-"}
                    </div>
                  </td>
                  <td className="bg-transparent league-name">{country}</td>
                  <td className="bg-transparent rounded-r-lg team-rating">
                    {medianSpi?.toFixed(1)}
                  </td>
                  {/* <td className="bg-transparent rounded-r-lg team-status">
                        <div className="px-2">
                            <h2>..</h2>
                            <img width={10} src={increaseIcon} alt="icon" />
                        </div>
                    </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default LeaguesRanking;
