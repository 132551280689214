import React, { useEffect, useState } from "react";

const Role = ({
    roleSelected,
    setRoleSelected,
    allRoles,
    // setSeasonSelected,
    // setTeamSelected,
    setPage,
}) => {
    const [roleToggle, setRoleToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoad, setIsLoad] = useState(true);

    const subHeaders = [
        {
            sub: "Forward CF",
            values: [
                {
                    position: "Forward (CF)",
                    role: "Target Man",
                    short: "(T-CF)",
                },
                {
                    position: "Forward (CF)",
                    role: "Second Striker",
                    short: "(SS)",
                },
                {
                    position: "Forward (CF)",
                    role: "Run-in-Behind CF",
                    short: "(R-CF)",
                },
                {
                    position: "Forward (CF)",
                    role: "Playmaking CF",
                    short: "(P-CF)",
                },
            ],
        },
        {
            sub: "Forward Wing",
            values: [
                {
                    position: "Forward (Wing)",
                    role: "Run-in-Behind Winger",
                    short: "(R-W)",
                },
                {
                    position: "Forward (Wing)",
                    role: "Manipulator Winger",
                    short: "(M-W)",
                },
            ],
        },
        {
            sub: "Midfielder Centre-Offensive",
            values: [
                {
                    position: "Midfield (Center, Offensive)",
                    role: "Defensive MF/Central MF",
                    short: "(D-MF)",
                },
                {
                    position: "Midfield (Center, Offensive)",
                    role: "Box to Box MF",
                    short: "(B-MF)",
                },
                {
                    position: "Midfield (Center, Offensive)",
                    role: "Playmaking CAM",
                    short: "(P-MF)",
                },
                {
                    position: "Midfield (Center, Offensive)",
                    role: "High Tempo CAM",
                    short: "(HT-MF)",
                },
            ],
        },
        {
            sub: "Midfield Defensive",
            values: [
                {
                    position: "Midfield (Defensive)",
                    role: "Holding Defensive MF",
                    short: "(H-DM)",
                },
                {
                    position: "Midfield (Defensive)",
                    role: "Playmaking Defensive MF",
                    short: "(P-DM)",
                },
            ],
        },
        {
            sub: "Defender Central",
            values: [
                {
                    position: "Defenders (Central)",
                    role: "Ball-Playing Central Defender",
                    short: "(B-CB)",
                },
                {
                    position: "Defenders (Central)",
                    role: "Physical Central Defender",
                    short: "(P-CB)",
                },
                {
                    position: "Defenders (Central)",
                    role: "Ball-Winning Central Defender",
                    short: "(W-CB)",
                },
                {
                    position: "Defenders (Central)",
                    role: "Versatile Central Defender",
                    short: "(V-CB)",
                },
            ],
        },
        {
            sub: "Defender Wide",
            values: [
                {
                    position: "Defender (Wide)",
                    role: "Technical Full Back",
                    short: "(T-FB)",
                },
                {
                    position: "Defender (Wide)",
                    role: "Athletic Full Back",
                    short: "(A-FB)",
                },
                {
                    position: "Defender (Wide)",
                    role: "Defensive Full Back (Stopper)",
                    short: "(D-FB)",
                },
                {
                    position: "Defender (Wide)",
                    role: "Versatile Full Back",
                    short: "(V-FB)",
                },
            ],
        },
        {
            sub: "Goalkeepers",
            values: [
                {
                    position: "Goalkeepers",
                    role: "Ball-Playing GK",
                    short: "(B-GK)",
                },
            ],
        },
    ];

    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.closest(".role-btn")) {
                setRoleToggle(false);
            }
        });
    }, []);

    const handleClick = () => {
        setRoleToggle(!roleToggle);
    };

    const handleRoleSelected = (league) => {
        setLoading(false);

        setRoleSelected(league);
        // setSeasonSelected("select season");
        // setTeamSelected("select team");
        setIsLoad(false);
        setPage(1);
    };

    return (
        <div className="mt-3 ">
            <h6 className="text-sm">Role</h6>
            {/* <div className=" flex flex-wrap gap-1 ">
                {selectedLeagues.length > 0 &&
                    selectedLeagues.map((league, index) => (
                        <div
                            key={index}
                            style={{ fontSize: "11px" }}
                            className="p-1 flex gap-1 justify-between items-center text-sm border rounded-e"
                        >
                            <div>{league}</div>
                            <div
                                onClick={() => handleRemoveLeague(league)}
                                className="border cursor-pointer rounded-full px-1 bg-white shadow-sm"
                            >
                                x
                            </div>
                        </div>
                    ))}
            </div> */}
            <div className="relative mt-2">
                <button
                    onClick={handleClick}
                    className="w-full xl:w-full text-sm input-border capitalize p-2 rounded-lg outline-none role-btn flex  bg-base-100"
                >
                    {loading && isLoad ? (
                        <svg
                            className="animate-spin mx-auto h-5 w-5 text-slate-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    ) : roleSelected.length > 0 ? (
                        roleSelected
                    ) : (
                        "Select Role"
                    )}
                </button>

                {roleToggle && (
                    <div className="absolute max-h-[300px] overflow-y-scroll league-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
                        {subHeaders?.map((item, index) => (
                            <React.Fragment key={index}>
                                <h2 className="p-1.5 text-neutral-content font-semibold text-md">
                                    {item.sub}
                                </h2>
                                {item?.values?.map((item2, idx) => (
                                    <p
                                        key={idx}
                                        onClick={() =>
                                            handleRoleSelected(item2.role)
                                        }
                                        className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
                                    >
                                        {item2.role}
                                    </p>
                                ))}
                            </React.Fragment>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Role;
