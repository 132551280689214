import React, { useContext, useEffect, useState } from "react";
import logo from "../../Images/logo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { BiSearch } from "react-icons/bi";
import { FiMenu } from "react-icons/fi";
import metric from "../../Images/metric.png";
import clubSearch from "../../Images/club-search.png";
import rankingIcon from "../../Images/ranking-icon.png";
import findSimilar from "../../Images/find-similar.png";
import comparison from "../../Images/comparison.png";
import shortList from "../../Images/short-list.png";
import playersData from "../../players.json";
import { debounce } from "lodash";
import { DataContext } from "../../Context/DataProvider";

const Header = () => {
  const [mobileMenu, setMobilMenu] = useState(false);
  const [searchToggle, setSearchToggle] = useState(false);
  const [financeDropdown, setFinanceDropdown] = useState(false);
  const [playerDropdown, setPlayerDropdown] = useState(false);
  const [coachDropdown, setCoachDropdown] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [players, setPlayers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchDropdown, setSearchDropdown] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { setUser, user, base_url, setPlayerShortListData, token } =
    useContext(DataContext);

  const navigate = useNavigate();

  const [activeMobileNav, setActiveMobileNav] = useState("dashboard");
  const [playersNav, setPlayersNav] = useState(false);

  const handlePlayerDetail = (name) => {
    setMobilMenu(false);
    setActiveMobileNav("");
  };

  const handleSignout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setPlayerShortListData([]);


    navigate("/");
  };

  useEffect(() => {
    if (searchedText !== "") {
      setLoading(true);

      const searchWords = searchedText.trim().split(/\s+/);
      const filteredResults = playersData.filter((player) => {
        const { name_team } = player;
        const lowerCaseName = name_team.toLocaleLowerCase();

        return searchWords.every((word) =>
          lowerCaseName.includes(word.toLowerCase())
        );
      });

      setPlayers(filteredResults.slice(0, 10));
      setLoading(false);
      setNotFound(false);
    } else {
      setLoading(false);
    }
  }, [searchedText]);

  const navigateToProfile = (name_dob) => {
    navigate(`/profile/${name_dob}`);
  };

  const text = (e) => {
    setSearchedText(e.target.value);
    setSearchDropdown(true);
  };
  const debounceOnChange = debounce(text, 400);

  let content;
  if (loading && players === null && searchedText.length > 0) {
    content = <progress className="progress loading w-56"></progress>;
  }

  if (players?.length === 0 && searchedText.length > 0) {
    content = <p className="not-found">No data found !</p>;
  }

  if (
    players?.length > 0 &&
    !loading &&
    players !== null &&
    searchedText.length > 0 &&
    searchDropdown === true
  ) {
    content = (
      <div className="w-[300px] md:w-[400px]">
        <ul className="player-list max-h-[350px] overflow-y-scroll b z-50 shadow-lg bg-base-100 p-2 rounded-box">
          <h6 className="text-xs text-neutral-content mt-2 mb-1 ml-3">
            PLAYERS
          </h6>
          {players?.map((player, index) => (
            <li key={index} onClick={() => handlePlayerDetail(player.name_dob)}>
              <div className="p-3 pt-2">
                <div
                  onClick={() => {
                    navigateToProfile(player.name_dob);
                    setSearchDropdown(false);
                  }}
                  className="text-sm cursor-pointer"
                >
                  <label htmlFor="mobile-menu" className="text-semibold">
                    {player.name_dob}
                  </label>
                  <p className="text-neutral-content text-medium">
                    {player.age} yr • {player.team}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        !e.target.closest(".searchBtn") &&
        !e.target.closest(".searchBox") &&
        !e.target.closest(".search_result")
      ) {
        setSearchToggle(false);
      }

      if (
        !e.target.closest(".financeBtn") &&
        !e.target.closest(".financeDropDown")
      ) {
        setFinanceDropdown(false);
      }

      if (
        !e.target.closest(".playerBtn") &&
        !e.target.closest(".playerDropDown")
      ) {
        setPlayerDropdown(false);
      }

      if (
        !e.target.closest(".coachBtn") &&
        !e.target.closest(".coachDropDown")
      ) {
        setCoachDropdown(false);
      }
    });
  }, []);

  const handleSearchIcon = () => {
    setSearchToggle(true);
    setPlayers(null);
  };

  return (
    <>
      <header className="bg-base-100 shadow-lg border-b border-[#f2f3f6]">
        <div className="header px-0 xl:px-10 h-[76px] flex items-center">
          <div className="w-full flex lg:grid grid-cols-3 items-center justify-between">
            <div className="w-full lg:w-max flex justify-between items-center relative">
              <div
                onClick={() => {
                  navigate("/");
                  // setPlayersNav(false);
                  setMobilMenu(false);
                  setActiveMobileNav("dashboard");
                }}
              >
                <img src={logo} alt="" className="w-36" />
              </div>

              <div>
                <button
                  onClick={() => {
                    setMobilMenu(!mobileMenu);
                    setIsDrawerOpen(!isDrawerOpen);
                  }}
                  className="lg:hidden"
                >
                  {/* <FiMenu className="text-2xl" /> */}
                  <label htmlFor="mobile-menu" className="">
                    <FiMenu className="text-2xl" />
                  </label>
                </button>
              </div>
            </div>

            <nav className="hidden lg:block">
              <ul className="text-neutral-content flex justify-center gap-10 font-semibold text-base">
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    Dashboard
                  </NavLink>
                </li>
                {/* <li>
                <NavLink to="/metric">Metric</NavLink>
              </li> */}
                <li>
                  <NavLink to="/teams">Team</NavLink>
                </li>
                {/* <li>
                  <div className="relative">
                    <button
                      className="financeBtn"
                      onClick={() => setFinanceDropdown(!financeDropdown)}
                    >
                      Finances
                    </button>

                    {financeDropdown && (
                      <div className="playerDropDown absolute w-[296px] top-[120%] p-3 bg-base-100 shadow-xl rounded-lg z-50">
                        <NavLink
                          to="/profile"
                          className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                        >
                          <div>
                            <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                              <BiSearch className="text-lg text-neutral" />
                            </div>
                          </div>
                          <div className="text-sm">
                            <h6 className="font-semibold text-neutral">
                              <NavLink to="/profile">Search</NavLink>
                            </h6>
                            <p className="font-medium">
                              Search for any player by name and analyse their
                              data
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/finance/upload-file"
                          className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                        >
                          <div>
                            <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                              <img src={metric} alt="" />
                            </div>
                          </div>
                          <div className="text-sm">
                            <h6 className="font-semibold text-neutral">
                              <NavLink to="/finance/upload-file">
                                File Upload
                              </NavLink>
                            </h6>
                            <p className="font-medium">
                              Filter worldwide, and shortlist players directly
                              from our database
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/finance/player-info"
                          className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                        >
                          <div>
                            <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                              <img src={clubSearch} alt="" />
                            </div>
                          </div>
                          <div className="text-sm">
                            <h6 className="font-semibold text-neutral">
                              <NavLink to="/finance/player-info">
                                Players Info
                              </NavLink>
                            </h6>
                            <p className="font-medium">
                              Search for any club and analysis their players
                            </p>
                          </div>
                        </NavLink>

                        <NavLink
                          to="/finance/squad"
                          className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                        >
                          <div>
                            <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                              <img src={shortList} alt="icon" />
                            </div>
                          </div>
                          <div className="text-sm">
                            <h6 className="font-semibold text-neutral">
                              <NavLink to="/finance/squad">Squad</NavLink>
                            </h6>
                            <p className="font-medium">
                              Manage, rank and follow your shortlisted players
                              throughout their careers
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    )}
                  </div>
                </li> */}
                <li>
                  {/* <NavLink to="/players">Players</NavLink> */}
                  <div className="relative">
                    <button
                      className="playerBtn"
                      onClick={() => setPlayerDropdown(!playerDropdown)}
                    >
                      Players
                    </button>

                    {playerDropdown && (
                      <div className="playerDropDown absolute w-[296px] top-[120%] p-3 bg-base-100 shadow-xl rounded-lg z-50">
                        {!['Rio Ave'].includes(user?.team) &&
                          <NavLink
                            to="/profile"
                            className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                          >
                            <div>
                              <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                                <BiSearch className="text-lg text-neutral" />
                              </div>
                            </div>
                            <div className="text-sm">
                              <h6 className="font-semibold text-neutral">
                                <NavLink to="/profile">Player Search</NavLink>
                              </h6>
                              <p className="font-medium">
                                Search for any player by name and analyse their
                                data
                              </p>
                            </div>
                          </NavLink>
                        }
                        <NavLink
                          to="/market-search"
                          className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                        >
                          <div>
                            <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                              <img src={metric} alt="" />
                            </div>
                          </div>
                          <div className="text-sm">
                            <h6 className="font-semibold text-neutral">
                              <NavLink to="/market-search">
                                Market Search
                              </NavLink>
                            </h6>
                            <p className="font-medium">
                              Filter worldwide, and shortlist players directly
                              from our database
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/scout-search"
                          className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                        >
                          <div>
                            <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                              <img src={metric} alt="" />
                            </div>
                          </div>
                          <div className="text-sm">
                            <h6 className="font-semibold text-neutral">
                              <NavLink to="/scout-search">
                                Scout Search
                              </NavLink>
                            </h6>
                            <p className="font-medium">
                              Filter worldwide, and shortlist players directly
                              from our database
                            </p>
                          </div>
                        </NavLink>
                        <NavLink
                          to="/club-search"
                          className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                        >
                          <div>
                            <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                              <img src={clubSearch} alt="" />
                            </div>
                          </div>
                          <div className="text-sm">
                            <h6 className="font-semibold text-neutral">
                              <NavLink to="/club-search">Club search</NavLink>
                            </h6>
                            <p className="font-medium">
                              Search for any club and analysis their players
                            </p>
                          </div>
                        </NavLink>

                        <NavLink
                          to="/short-lists"
                          className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                        >
                          <div>
                            <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                              <img src={shortList} alt="icon" />
                            </div>
                          </div>
                          <div className="text-sm">
                            <h6 className="font-semibold text-neutral">
                              <NavLink to="/short-lists">Shortlist</NavLink>
                            </h6>
                            <p className="font-medium">
                              Manage, rank and follow your shortlisted players
                              throughout their careers
                            </p>
                          </div>
                        </NavLink>
                      </div>
                    )}
                  </div>
                </li>

                {/* /Coach */}
                {!['Rio Ave'].includes(user?.team) &&
                  <li>
                    <div className="relative">
                      <button
                        className="coachBtn"
                        onClick={() => setCoachDropdown(!coachDropdown)}
                      >
                        Coaches
                      </button>

                      {coachDropdown && (
                        <div className="coachDropDown absolute w-[296px] top-[120%] p-3 bg-base-100 shadow-xl rounded-lg z-50">
                          <NavLink
                            to="/coach-search"
                            className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                          >
                            <div>
                              <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                                <BiSearch className="text-lg text-neutral" />
                              </div>
                            </div>
                            <div className="text-sm">
                              <h6 className="font-semibold text-neutral">
                                <NavLink to="/coach-search">Coach Search</NavLink>
                              </h6>
                              <p className="font-medium">
                                Search for any coach by name and analyse their
                                data
                              </p>
                            </div>
                          </NavLink>
                          <NavLink
                            to="coach-metric"
                            className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                          >
                            <div>
                              <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                                <img src={metric} alt="" />
                              </div>
                            </div>
                            <div className="text-sm">
                              <h6 className="font-semibold text-neutral">
                                Market Search
                              </h6>
                              <p className="font-medium">
                                Filter worldwide, and shortlist coaches directly
                                from our database
                              </p>
                            </div>
                          </NavLink>

                          <NavLink
                            to="/coach-shortlists"
                            className="cursor-pointer p-3 hover:bg-[#F7F7F8] duration-300 flex items-center gap-3"
                          >
                            <div>
                              <div className="border rounded-lg w-9 h-9 flex justify-center items-center">
                                <img src={shortList} alt="icon" />
                              </div>
                            </div>
                            <div className="text-sm">
                              <h6 className="font-semibold text-neutral">
                                Shortlist
                              </h6>
                              <p className="font-medium">
                                Manage, rank and follow your shortlisted coaches
                                throughout their careers
                              </p>
                            </div>
                          </NavLink>
                        </div>
                      )}
                    </div>
                  </li>
                }
              </ul>
            </nav>

            <div className="hidden lg:flex justify-end items-center gap-3 text-neutral">
              <NavLink
                to="/rankings"
                className="w-[44px] h-11 rounded-lg border flex justify-center items-center"
              >
                <img src={rankingIcon} alt="icon" />
              </NavLink>
              <div>
                <button
                  onClick={handleSearchIcon}
                  className={`${searchToggle ? "hidden" : "flex"
                    } w-[44px] h-11 rounded-lg border justify-center items-center searchBtn`}
                >
                  <BiSearch className="text-lg" />
                </button>

                {searchToggle && (
                  <div className="relative">
                    <input
                      onChange={debounceOnChange}
                      type="text"
                      className="searchBox p-3 pl-11 text-neutral rounded-lg border-primary h-10 w-[256px] outline-none"
                    />

                    <button
                      onClick={() => setSearchToggle(false)}
                      className="absolute top-3 left-3 pr-2 border-gray-400"
                    >
                      <BiSearch className="text-lg" />
                    </button>
                    {content}
                  </div>
                )}
              </div>

              {user && user.email ? (
                <button
                  onClick={handleSignout}
                  className="w-[96px] h-11 flex justify-center items-center rounded-lg bg-primary text-base-100 font-semibold"
                >
                  Sign out
                </button>
              ) : (
                <Link
                  to="login"
                  className="w-[96px] h-11 flex justify-center items-center rounded-lg bg-primary text-base-100 font-semibold"
                >
                  Login
                </Link>
              )}
            </div>
          </div>
        </div>
      </header>

      {/* mobile sidebar */}
      <div
        style={{ transitionProperty: "width", transitionDuration: "0.5s" }}
        className={`${mobileMenu ? "  w-[75%]" : "w-0"
          } absolute h-[90vh] z-[1000] md:hidden `}
      >
        <ul
          style={{ transitionProperty: "width", transitionDuration: "0.5s" }}
          className={`menu  ${mobileMenu ? "w-80 p-4" : "w-0 p-0"
            } rounded-none min-h-full shadow-xl md:hidden bg-base-100 text-neutral-content overflow-x-hidden font-semibold text-base`}
        >
          {mobileMenu && (
            <li className="px-0">
              <div className="w-full pl-[9px] pr-0 bg-transparent">
                <div className="relative pr-0 f-full">
                  <input
                    onChange={debounceOnChange}
                    type="text"
                    placeholder="Search for a player"
                    className={`searchBox p-3 pl-9 text-neutral focus:outline-none text-sm font-medium rounded-lg border h-10  ${searchDropdown && searchedText && players?.length > 0
                      ? "w-[90%]"
                      : "w-[125%]"
                      } outline-none`}
                  />

                  <button
                    onClick={() => setSearchToggle(false)}
                    className="absolute top-3 left-3 pr-2 border-gray-400"
                  >
                    <BiSearch className="text-lg" />
                  </button>
                  {content}
                </div>
              </div>
            </li>
          )}

          {mobileMenu && (
            <li className={` px-2 py-1 whitespace-nowrap overflow-x-hidden`}>
              <label
                onClick={() => {
                  navigate("/");
                  setActiveMobileNav("dashboard");
                  setMobilMenu(false);
                  // setPlayersNav(false);
                }}
                className={`${activeMobileNav === "dashboard"
                  ? "text-[#2C56EB] bg-[#2C56EB14]"
                  : ""
                  }`}
                htmlFor="mobile-menu"
              >
                <div className="flex gap-2 items-center ">
                  <div className="border rounded-lg w-7 h-7 flex justify-center items-center">
                    <img className="w-5" src={clubSearch} alt="icon" />
                  </div>
                  <div>Dashboard</div>
                </div>
              </label>
            </li>
          )}

          {/* collapse nav items */}
          {/* {mobileMenu && (
            <li className={` px-2 py-1 whitespace-nowrap overflow-x-hidden`}>
              <div className={` ${(playersNav) ? "bg-[#2C56EB14] text-[#2C56EB]" :"bg-transparent"} `} onClick={() => setPlayersNav(!playersNav)}>Players</div>
              <div
                style={{
                  transitionProperty: "height",
                  transitionDuration: "0.5s",
                }}
                className={` ${
                  playersNav ? "h-auto" : "h-0 p-0"
                }  flex bg-transparent flex-col justify-start text-left gap-4 `}
              >
                <div
                  onClick={() => {
                    navigate("/market-search");
                    setActiveMobileNav("player-metric");
                    setMobilMenu(false);
                  }}
                  className={` ${playersNav ? "h-auto" : "h-0"} ${
                    activeMobileNav === "player-metric"
                      ? "text-[#2C56EB] "
                      : ""
                  }`}
                >
                  <div
                    className={` gap-2 ${
                      playersNav ? "flex ml-[-35%]" : "h-0 hidden"
                    } items-center  `}
                  >
                    <div
                      className={` rounded-lg ${
                        playersNav ? "h-7 border" : "h-0"
                      } w-7 flex justify-center items-center`}
                    >
                      <img
                        className={`${playersNav ? "h-auto" : "h-0"} w-3`}
                        src={metric}
                        alt="icon"
                      />
                    </div>
                    <div className={`${playersNav ? "h-7" : "h-0 hidden"}`}>
                      Market Search
                    </div>
                  </div>
                </div>
                <div
                  onClick={() => {
                    navigate("/short-lists");
                    setActiveMobileNav("player-shortlist");
                    setMobilMenu(false);
                  }}
                  className={` ${playersNav ? "h-auto" : "h-0"} ${
                    activeMobileNav === "player-shortlist"
                      ? "text-[#2C56EB] "
                      : ""
                  }`}
                >
                  <div
                    className={` gap-2 ${
                      playersNav ? "flex ml-[-35%]" : "h-0 hidden"
                    } items-center `}
                  >
                    <div
                      className={` rounded-lg ${
                        playersNav ? "h-7 border" : "h-0"
                      } w-7 flex justify-center items-center`}
                    >
                      <img
                        className={`${playersNav ? "h-auto" : "h-0"} w-3`}
                        src={shortList}
                        alt="icon"
                      />
                    </div>
                    <div className={`${playersNav ? "h-7" : "h-0 hidden"}`}>
                      Player Shortlist
                    </div>
                  </div>
                </div>
              </div>
            </li>
          )} */}

          {mobileMenu && (
            <li className={` px-2 py-1 whitespace-nowrap overflow-x-hidden`}>
              <label
                onClick={() => {
                  navigate("/market-search");
                  setActiveMobileNav("player-metric");
                  setMobilMenu(false);
                }}
                className={`${activeMobileNav === "player-metric"
                  ? "text-[#2C56EB] bg-[#2C56EB14]"
                  : ""
                  }`}
                htmlFor="mobile-menu"
              >
                <div className="flex gap-2 items-center ">
                  <div className="border rounded-lg w-7 h-7 flex justify-center items-center">
                    <img className="w-3" src={metric} alt="icon" />
                  </div>
                  <div>Market Search</div>
                </div>
              </label>
            </li>
          )}

          {mobileMenu && (
            <li className={` px-2 py-1 whitespace-nowrap overflow-x-hidden`}>
              <div
                onClick={() => {
                  navigate("/short-lists");
                  setActiveMobileNav("player-shortlist");
                  setMobilMenu(false);
                }}
                className={`${activeMobileNav === "player-shortlist"
                  ? "text-[#2C56EB] bg-[#2C56EB14]"
                  : ""
                  }`}
              >
                <div className="flex gap-2 items-center ">
                  <div className="border rounded-lg w-7 h-7 flex justify-center items-center">
                    <img className="w-3" src={shortList} alt="icon" />
                  </div>
                  <div>Player Shortlist</div>
                </div>
              </div>
            </li>
          )}

          {mobileMenu && (
            <li className={` px-2 mt-3 py-1 overflow-x-hidden`}>
              {user && user.email ? (
                <button
                  onClick={handleSignout}
                  className={` ${mobileMenu ? "w-[120px] h-11" : "w-[0] "
                    } flex justify-center items-center rounded-lg bg-primary text-base-100 font-semibold`}
                >
                  Sign out
                </button>
              ) : (
                <Link
                  to="login"
                  className="w-[120px] h-11 flex justify-center items-center rounded-lg bg-primary text-base-100 font-semibold"
                >
                  Login
                </Link>
              )}
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default Header;
