import React, { useEffect, useState } from "react";

const allPotentials = [
    {
        full: "ELT (Elite Team)",
        value: "ELT",
    },
    {
        full: "PRM (Premier Team)",
        value: "PRM",
    },
    {
        full: "STR (Strong Team)",
        value: "STR",
    },
    {
        full: "CMP (Competitive Team)",
        value: "CMP",
    },
    {
        full: "LWT (Lower Tier Team)",
        value: "LWT",
    },
];

const PlayerPotential = ({
    playerPotentialSelected,
    setPlayerPotentialSelected,
    setPage,
    selectedPlayerPotentials,
    setSelectedPlayerPotentials,
}) => {
    const [potentialToggle, setPotentialToggle] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {
        window.addEventListener("click", (e) => {
            if (!e.target.closest(".potential-btn")) {
                setPotentialToggle(false);
            }
        });
    }, []);

    const handleClick = () => {
        setPotentialToggle(!potentialToggle);
    };

    const handlePlayerPotentialSelected = (potential) => {
        setLoading(false);
        const potentials = [...selectedPlayerPotentials, potential];
        setSelectedPlayerPotentials([...new Set(potentials)]);
        setPlayerPotentialSelected(potential);
        setIsLoad(false);
        setPage(1);
    };

    

    const handleRemoveLeague = (potential) => {
        const filteredLeagues = selectedPlayerPotentials.filter(
            (item) => item !== potential
        );
        setSelectedPlayerPotentials(filteredLeagues);
        setPlayerPotentialSelected(null);
        
    };

    return (
        <div className="mt-3 ">
            <h6 className="text-sm">Player Potential</h6>
            <div className=" flex flex-wrap gap-1 ">
                {selectedPlayerPotentials.length > 0 &&
                    selectedPlayerPotentials.map((potential, index) => (
                        <div
                            key={index}
                            style={{ fontSize: "11px" }}
                            className="p-1 flex gap-1 justify-between items-center text-sm border rounded-e"
                        >
                            <div>{potential}</div>
                            <div
                                onClick={() => handleRemoveLeague(potential)}
                                className="border cursor-pointer rounded-full px-1 bg-white shadow-sm"
                            >
                                x
                            </div>
                        </div>
                    ))}
            </div>
            <div className="relative mt-2">
                <button
                    onClick={handleClick}
                    className="w-full xl:w-full text-sm input-border capitalize p-2 rounded-lg outline-none potential-btn flex  bg-base-100"
                >
                    {loading && isLoad ? (
                        <svg
                            className="animate-spin mx-auto h-5 w-5 text-slate-400"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                        >
                            <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                            ></circle>
                            <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                        </svg>
                    ) : playerPotentialSelected?.length > 0 ? (
                        playerPotentialSelected
                    ) : (
                        "Select player potential"
                    )}
                </button>

                {potentialToggle && (
                    <div className="absolute bottom-full max-h-[300px] overflow-y-scroll league-dropdown w-full bg-base-100 shadow-lg mt-1 rounded-lg  z-50 border">
                        {allPotentials.map((item, index) => (
                            <p
                                key={index}
                                onClick={() =>
                                    handlePlayerPotentialSelected(item.value)
                                }
                                className="cursor-pointer p-1.5 text-sm hover:bg-[#F7F7F8] duration-300"
                            >
                                {item.full}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PlayerPotential;
