import React from "react";
import arrow from "../../Images/arrow.png";

const HistoryTable = ({ playerDetails }) => {
  // console.log(playerDetails);
  return (
    <>
      {playerDetails?.length>0 && (
        <div className="mt-3 player_table bg-base-100 rounded-lg shadow-lg">
          <div className="px-4 xl:px-6 h-[45px] xl:h-[56px] flex justify-between items-center font-semibold text-neutral border-b text-sm xl:text-base">
            <div className="">
              <h6>History</h6>
            </div>
            <div className="relative">
              <button className="w-[128px] font-semibold text-neutral input-border py-2 px-3 rounded-lg outline-none flex justify-between items-center bg-base-100 text-xs xl:text-sm">
                <p>Performance</p>
                <img src={arrow} alt="" />
              </button>
            </div>
          </div>

          <div>
            <div className="py-4 overflow-x-scroll font-semibold">
              <table className="table w-[100%] md:w-[90%]">
                {/* head */}
                <thead className="text-neutral-content">
                  <tr>
                    <th>
                      <div className=" w-[166px] ml-[5px] md:ml-[-6px] xl:w-[177px] 2xl:ml-1 flex gap-6 items-center">
                        <h6>SEASON</h6>
                        <h6>TEAM</h6>
                      </div>
                    </th>
                    <th>
                      <div className="w-[120px] ml-[2px] md:ml-[-14px] 2xl:w-[150px] grid grid-cols-2 gap-6 items-center text-center">
                        <h6>LEAGUE</h6>
                        <h6>MINUTES</h6>
                      </div>
                    </th>

                    <th>
                      <div className="grid grid-cols-5 ml-[2px] md:ml-[-15px] w-[250px] xl:w-[280px] 2xl:w-[300px] gap-[25px] items-center text-center">
                        <h6> </h6>
                        <h6>OFF</h6>
                        <h6>DEF</h6>
                        <h6>TOTAL</h6>
                        <h6> </h6>
                      </div>
                    </th>

                    <th>
                      <div className="pr-3 2xl:pr-0 ml-0 md:ml-[-15px] grid grid-cols-5 gap-3 items-center w-[240px] 2xl:w-[272px] text-center">
                        <h6>BPR</h6>
                        <h6>SCR</h6>
                        <h6>PRT</h6>
                        <h6>DIM</h6>
                        <h6>DFR</h6>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* row 1 */}
                  {playerDetails?.map((history) => (
                    <tr>
                      <td>
                        <div className="pl-3 2xl:pl-5 flex gap-10 xl:gap-9 2xl:gap-7 items-center text-xs xl:text-sm 2xl:text-base">
                          <h6 className="text-neutral-content">
                            {history.season.split("-").length !== 1
                              ? history.season.split("-")[0]
                              : history.season}
                          </h6>
                          <h6 className="text-neutral">
                            {history.team_short_name}
                          </h6>
                        </div>
                      </td>
                      <td>
                        <div className="w-[120px] 2xl:w-[150px] grid grid-cols-2 gap-6 items-center text-neutral-content text-xs xl:text-sm 2xl:text-base text-center">
                          <h6>{history.league_code}</h6>
                          <h6>{history.minutes}</h6>
                        </div>
                      </td>

                      <td>
                        <div className="grid grid-cols-5 w-[250px] xl:w-[280px] 2xl:w-[300px] gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                          <div className="flex justify-center">
                            <div className="w-[1px] h-[24px] bg-gray-200"></div>
                          </div>

                          <div className=" h-[32px] flex justify-center items-center rounded border ">
                            <p>{history?.x_gfar}</p>
                          </div>
                          <div className=" h-[32px] flex justify-center items-center rounded border ">
                            <p>{history?.x_gaar}</p>
                          </div>
                          <div className="w-14 h-8 rounded flex justify-center border items-center">
                            <p>{history?.x_gdar}</p>
                          </div>
                          <div className="flex justify-center">
                            <div className="w-[1px] h-[24px] bg-gray-200"></div>
                          </div>
                        </div>
                      </td>

                      <td>
                        <div className="pr-3 2xl:pr-5 flex gap-2 items-center text-xs xl:text-sm 2xl:text-base">
                          <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                            <p>{history?.attack_bp}</p>
                          </div>

                          <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                            <p>{history?.attack_sc}</p>
                          </div>

                          <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                            <p>{history?.ball_ret}</p>
                          </div>

                          <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                            <p>{history?.def_qual}</p>
                          </div>

                          <div className="w-10 2xl:w-[48px] h-[32px] flex justify-center items-center bg-[#F7F7F8] rounded">
                            <p>{history?.def_quant}</p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default HistoryTable;
