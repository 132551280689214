
function comparePlayersObjectValues(key, order = "ascending") {
  return function innerSort(data1, data2) {
    if (!data1.hasOwnProperty(key) || !data2.hasOwnProperty(key)) {
      return 0;
    }

    let valueOfSpecificKeyOfData1 =
      typeof data1 === "string" ? data1[key].toUpperCase() : data1[key];

    if (key === "minutes" || key==="attack_sc" || key==="x_gdar" || key==="attack_bp" || key==="def" || key==="total" || key==="off" || key==="games" || key==="ball_ret" || key==="def_qual" || key==="def_quant" || key==="rJ" || key==="rN" || key==="delta" ) {
      valueOfSpecificKeyOfData1 = Number(data1[key]);
    }

    let valueOfSpecificKeyOfData2 =
      typeof data2 === "string" ? data2[key].toUpperCase() : data2[key];

    if (key === "minutes" || key==="attack_sc" || key==="x_gdar" || key==="attack_bp" || key==="off" || key==="def" || key==="total" || key==="games" || key==="ball_ret" || key==="def_qual" || key==="def_quant" || key==="rJ" || key==="rN" || key==="delta" ) {
      valueOfSpecificKeyOfData2 = Number(data2[key]);
    }

    let comparison = 0;
    if (valueOfSpecificKeyOfData1 > valueOfSpecificKeyOfData2) {
      comparison = 1;
    } else if (valueOfSpecificKeyOfData1 < valueOfSpecificKeyOfData2) {
      comparison = -1;
    }

    return order === "descending" ? comparison * -1 : comparison;
  };
}

export const sortBasedOnKey = (key, toggle, players, setPlayers) => {
  // console.log(toggle);
  // console.log('sort', players);
  console.log(key);
  if (toggle) {
    setPlayers([...players].sort(comparePlayersObjectValues(key)));
  } else {
    setPlayers(
      [...players].sort(comparePlayersObjectValues(key, "descending"))
    );
  }
};
